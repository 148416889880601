import { Segment, watermelon, Form, Table } from '@waylay/react-components'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Gravatar from 'react-gravatar'
import { GeneralItem } from '~/components/Common/GeneralItem'

export const Header = styled.header`
  font-size: 1.15rem;
  font-weight: 600;
  margin-bottom: 1rem;
`

export const SegmentHeader = styled(Segment.Header)`
  font-weight: 500;
`

export const SegmentGroup = styled(Segment.Group)`
  margin-bottom: 1.25em;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 250px minmax(250px, auto) 120px 70px 70px;
  grid-gap: 1em 1em;
  align-items: center;
  padding: 1em;
`

export const Ellipsified = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Key = styled.span(Ellipsified)
export const Value = styled.span(Ellipsified)

export const Avatar = styled(Gravatar)`
  width: 40px;
  height: 40px;
  margin-right: 0.6em;
  border-radius: 50%;
`

export const Actions = styled.div`
  visibility: hidden;
`

export const ResourceItemHover = styled(GeneralItem)`
  &:hover {
    ${Actions} {
      visibility: visible;
    }
  }
`

export const Links = styled.div`
  button {
    margin-right: 0.5em;
  }
`

export const FieldLabel = styled.label`
  margin-bottom: 0.5em;
`

export const CenterFlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BaselineLayout = styled.div`
  display: flex;
  align-items: baseline;
`

export const SmallErrorMessage = styled.span`
  font-size: 0.9em;
  color: ${watermelon};
`

export const FormBlock = styled(Form.Input.Group)`
  width: 100%;
  display: block;
`

export const TableCellRight = styled(Table.Cell)`
  text-align: right;
`

export const FormSegment = styled(Segment.Group)`
  width: 250px;
`

export const ListSegment = styled(Segment.Group)`
  width: 600px;
  margin-left: 15px;
`

export const TransparentDescription = styled.div`
  color: hsl(0, 0%, 45%);
`

export const HeaderBox = styled(Segment)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

export const StyledCheckbox = styled.label`
  display: flex !important;
  align-items: center;
  flex-basis: 50%;
  > input {
    margin-right: 0.4em;
  }
`
