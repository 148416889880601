import React from 'react'
import { get } from 'lodash-es'
import AttributesInput from './AttributesInput'
import { IResourceType } from '../../../Resources.types'
import { ConstraintAttribute } from '~/components/ResourceConstraints/Constraints.types.'

const AttributesForm = ({
  attributes = [],
  resourceType,
}: {
  attributes?: ConstraintAttribute[]
  resourceType?: IResourceType
}) => {
  return (
    <>
      {attributes.map(attr => {
        const { name } = attr
        const placeholder = get(resourceType, name)
        return (
          <AttributesInput
            key={name}
            attribute={attr}
            placeholder={placeholder}
          />
        )
      })}
    </>
  )
}

export default AttributesForm
