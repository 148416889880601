import { get } from 'lodash-es'
import client from '../../../../../lib/client'
import { IResourceType } from '../../../Resources.types'
import { ConstraintAttribute } from '~/components/ResourceConstraints/Constraints.types.'
import { useQuery } from '@tanstack/react-query'

const fetchResourceTypeFn = async (id: string) => {
  const response = await client.types.get(id)
  return response
}

const fetchConstraintsFn = async (id: string) => {
  const response = await client.types.constraints(id)
  return response
}

const useAttributes = (resourceTypeId: string) => {
  const { data: constraintsFetch } = useQuery({
    queryKey: [`attributes`, resourceTypeId],
    queryFn: async () => await fetchConstraintsFn(resourceTypeId),
  })

  const { data: resourceType } = useQuery({
    queryKey: [`resource-types`, resourceTypeId],
    queryFn: async () => await fetchResourceTypeFn(resourceTypeId),
  })

  const attributes = get(
    constraintsFetch,
    'attributes',
    [],
  ) as ConstraintAttribute[]

  const filteredAttributes = attributes
    .filter(attr => attr.required)
    .filter(attr => attr.name !== 'id')

  return {
    filteredAttributes,
    resourceType: resourceType as IResourceType,
  }
}

export default useAttributes
