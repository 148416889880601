import querystring from 'query-string'
import searchString from 'search-string'
import {
  random,
  reduce,
  isNil,
  last,
  groupBy,
  sortBy,
  toInteger,
  get,
  isPlainObject,
  isEqual,
  isObject,
  transform,
  isNumber,
  isEmpty,
  omitBy,
} from 'lodash-es'
import cartesian from 'cartesian'
import { scaleOrdinal } from 'd3-scale'
import { schemeTableau10 } from 'd3-scale-chromatic'
import {
  colors,
  watermelon,
  tangerine,
  lemon,
  leaf,
  clearSky,
  amethyst,
} from '@waylay/react-components'
import mem from 'mem'
import semver, { sort as semverSort } from 'semver'
import { PlugV0, PropertyFormatType } from '~/lib/types'
import {
  PlugV2,
  PlugsV2Entity,
} from '~/components/PluginsAndWebscripts/Common/Types'
import { EFrequency } from '~/components/Settings/Settings.types'
import { EXPORT_FREQUENCY_PERIODS, RESOURCES_INPUT_TYPES } from './Constants'
import { RRule } from 'rrule'
import { format } from 'd3-format'
import { DateTime } from 'luxon'
import { LicenseDetails } from '@waylay/client/dist/auth/license'

const { Blob, URL } = window
const BOOLEAN_VALUES_AS_STRINGS = ['true', 'false']

export function parseFilterQuery(search: string) {
  return parseQueryParam(search, 'query')
}

export function parseSort(search: string) {
  return parseQueryParam(search, 'sort')
}

export function getQuery(search: string) {
  const { query = '' } = querystring.parseUrl(search).query
  return query
}

// sets a querystring param without modifying the existing search query
export function setSearchParam(search: string, key: string, value: string) {
  const params = new URLSearchParams(search)
  params.set(key, value)

  return params.toString()
}

// sets multiple querystring params without modifying the existing search query
export function setMultipleSearchParam(search: string, queryParams: object) {
  const params = new URLSearchParams(search)
  Object.entries(queryParams).forEach(([key, value]) => {
    // a null-key assumes we don't want it in the querystring
    if (value == null) {
      params.delete(key)
      return
    }

    const qsValue =
      isPlainObject(value) || Array.isArray(value)
        ? JSON.stringify(value)
        : value

    params.set(key, qsValue)
  })

  return params.toString()
}

// appends a querystring param without modifying the existing search query
export function appendSearchParam(search: string, key: string, value: string) {
  const params = new URLSearchParams(search)
  params.append(key, value)

  return params.toString()
}

export const randomColor = mem(_randomColor, {
  cacheKey: arguments_ => arguments_.join(),
})

export function _randomColor(string?: string) {
  const colorsArray = [watermelon, tangerine, lemon, leaf, clearSky, amethyst]
  const weights = [300, 400, 500, 600, 700]

  // we have (colorsArray * weights) different combinations, so we create
  // the cartesian product of those arrays
  //
  // then we use a simple pearsonHash where the max value it returns is (colorsArray * weights)
  // this makes the value deterministic and fits our cartesian product array
  if (string) {
    const product = cartesian([colorsArray, weights])
    const number = pearsonHash(string, product.length)
    const [color, weight] = product[number]

    return colors.withWeight(color, weight)
  } else {
    const randomColorIndex = random(0, colorsArray.length - 1)
    const randomWeightIndex = random(0, weights.length - 1)

    return colors.withWeight(
      colorsArray[randomColorIndex],
      weights[randomWeightIndex],
    )
  }
}

export function randomTagColor() {
  const colorsArray = [watermelon, tangerine, lemon, leaf, clearSky, amethyst]
  const weights = [200, 300, 400, 500]

  const randomColorIndex = random(0, colorsArray.length - 1)
  const randomWeightIndex = random(0, weights.length - 1)

  return colors.withWeight(
    colorsArray[randomColorIndex],
    weights[randomWeightIndex],
  )
}

export function pearsonHash(message: string, tableSize = 256) {
  const table = [...new Array(tableSize)].map((_, i) => i)

  return message.split('').reduce((hash, c) => {
    return table[(hash + c.charCodeAt(0)) % (table.length - 1)]
  }, message.length % (table.length - 1))
}

const ordinalColorGenerator = scaleOrdinal(schemeTableau10)
export function chromaticScaleColor(string = '') {
  return ordinalColorGenerator(string)
}

export function getStateColorFromStates(state = '', possibleStates = []) {
  // Exceptions in line with: https://github.com/waylayio/graph-editor/blob/23682929b9a13cabf0e9cf052b4b8c38057d3fee/src/themes/light.js#L12-L13
  if (state.match(/true/i)) return '#60D53E'
  if (state.match(/false/i)) return '#d53e4f'

  const scale = scaleOrdinal().range(schemeTableau10).domain(possibleStates)

  return scale(state)
}

export const validUUIDRegex =
  '[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}'

export function numberGenerator(graph: any) {
  // filter for nodes with a plug
  const plugs = graph.graph
    .nodes()
    .filter(node => node.data('plug'))
    .map(node => ({ type: node.data('plug'), label: node.data('label') }))

  // filter for gates, these should also have unique names
  const gates = graph.graph
    .nodes()
    .filter(node => node.data('type') === 'gate')
    .map(node => ({ type: node.data('gateType'), label: node.data('label') }))

  // group nodes by name
  const groupedPlug = groupBy(plugs, plug => plug.type.name)
  const initialPlugs: Array<[string, number]> = Object.entries(groupedPlug).map(
    ([key, values]) => [key, highestPlugNumber(values)],
  )

  // create map for gate nodes
  const groupedGates = groupBy(gates, gate => gate.type)
  const initialGates: Array<[string, number]> = Object.entries(
    groupedGates,
  ).map(([key, values]) => [key, highestPlugNumber(values)])

  const plugsMap: Map<string, number> = new Map(initialPlugs)
  const gatesMap: Map<string, number> = new Map(initialGates)

  return {
    next: (label: string, type: string) => {
      if (type === 'gate') {
        return incrementedOccurenceNumber(gatesMap, label)
      } else {
        return incrementedOccurenceNumber(plugsMap, label)
      }
    },
    reset: () => {
      plugsMap.clear()
      gatesMap.clear()
    },
  }
}

function highestPlugNumber(plugs) {
  return reduce(
    plugs,
    (highestInt, { label }) => {
      const current = toInteger(last(label.split('_')))
      return current > highestInt ? current : highestInt
    },
    0,
  )
}

function incrementedOccurenceNumber(map: Map<string, number>, label: string) {
  if (!map.has(label)) {
    map.set(label, 0)
  }

  const incremented = map.get(label) + 1
  map.set(label, incremented)

  return incremented
}

/**
 * Very simple upload text handler
 */
export function handleUpload(e, onRead) {
  e.preventDefault()
  const dummyInput = document.createElement('input')
  dummyInput.type = 'file'
  dummyInput.accept = 'application/json,.json'
  dummyInput.onchange = handleInputChange
  dummyInput.click()

  function handleInputChange(e) {
    const reader = new FileReader()
    reader.onload = onRead
    reader.readAsText(e.target.files[0])
  }
}

export const flattenObject = obj => {
  const flattened = {}

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}

// export function sortByIgnoreCase(collection, key, ...args) {
//   return sortBy(collection, [item => get(item, key).toLowerCase()], ...args)
// }

export function testDeclarativelyBound(
  _value: string | number | Object = '',
  property?: any,
): boolean {
  if (
    property?.format &&
    (property?.format?.type === PropertyFormatType.AiPluginDescriptor ||
      property?.format?.type === PropertyFormatType.AiTemplateDescriptor)
  ) {
    return false
  }
  const value = String(_value)
  // count the amount of brackets. Should be equal
  const openBracketCount = String(value).split('{').length
  const closedBracketCount = String(value).split('}').length

  if (openBracketCount !== closedBracketCount) {
    return false
  }

  const singleBracketRegex = /(?:\\)?\${[^${}\s]+\}/g
  const doubleBracketRegex = /(?:\\)?\${{[^${}\s]+\}}/g

  const singleMatches = value.match(singleBracketRegex) ?? []
  const doubleMatches = value.match(doubleBracketRegex) ?? []
  const matches = [].concat(singleMatches, doubleMatches)

  // Detect escaped binding
  return matches.some(group => !group.startsWith('\\'))
}

export function generateColor() {
  const randomNumber = Math.floor(Math.random() * 0xffffff + 1)
  return `#${randomNumber.toString(16)}` // print in hex
}

export function difference<TObject, TSource>(
  object: TObject,
  base: TSource,
): Partial<TObject & TSource> {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value
      }
    })
  }
  return changes(object, base) as Partial<TObject & TSource>
}

export const extractReferenceId = (value: any) => {
  const ref: string = get(value, '$ref', '')
  return ref && ref.length > 11
    ? decodeURIComponent(ref.substring(11, ref.length))
    : null
}

export const extractValueType = (value: any) => {
  if (typeof value === 'boolean') {
    return RESOURCES_INPUT_TYPES.BOOLEAN
  } else if (isNumber(value)) {
    return RESOURCES_INPUT_TYPES.NUMBER
  } else if (typeof value === 'object') {
    return RESOURCES_INPUT_TYPES.OBJECT
  } else if (extractReferenceId(value) !== null) {
    return RESOURCES_INPUT_TYPES.REFERENCE
  } else return RESOURCES_INPUT_TYPES.STRING
}

export const getValidationMessage = constraint => {
  let message = ''

  switch (constraint.type) {
    case RESOURCES_INPUT_TYPES.NUMBER: {
      message = `The value you enter should be a number between ${constraint.minimum} and ${constraint.maximum}`
      break
    }

    case RESOURCES_INPUT_TYPES.STRING: {
      message = `The number of characters should be between ${constraint.minLength} and ${constraint.maxLength}`
      break
    }
    default:
      message = 'Validation failed'
      break
  }
  return message
}

export const validateConstraint = (value, inputType, constraint) => {
  let valid = true
  switch (inputType) {
    case RESOURCES_INPUT_TYPES.NUMBER: {
      const min = constraint?.type?.minimum
      const max = constraint?.type?.maximum
      if (value < min || value > max) {
        valid = false
      }
      break
    }
    case RESOURCES_INPUT_TYPES.STRING: {
      const min = constraint?.type?.minLength
      const max = constraint?.type?.maxLength
      const charactersNr = value.length
      if (charactersNr > 0 && (charactersNr < min || charactersNr > max)) {
        valid = false
      }
      break
    }
  }

  return valid
}

export const removeAllDoubleQuotes = (value: any): string => {
  return typeof value === 'string' ? value.replace(/"/g, '') : value
}

export const getSortedPlugVersions = (versions: PlugV0[] = []) => {
  return semverSort(versions.map(plug => plug.version)).reverse()
}

export const getSortedPlugVersionsV2 = plugs => {
  return semverSort(plugs.map(data => data.plug.version)).reverse()
}

export const normalizeForPartialMatch = (
  value?: string | null,
): string | undefined => {
  if (!isEmpty(value)) {
    return value.includes('*') || value.includes('?') ? value : `*${value}*`
  }
}

function _isEmptyProp(obj: Object) {
  return (
    obj == null ||
    (typeof obj === 'object' && Object.keys(obj).length === 0) ||
    (typeof obj === 'string' && obj.trim().length === 0)
  )
}

export function hasDuplicates<T>(array: T[]): boolean {
  return new Set(array).size !== array.length
}

export const removeEmptyProps = (obj: Object) =>
  omitBy(obj, _isEmptyProp) as Object

export const removeEmptyPropsLoop = (obj: Object) =>
  omitBy(obj, isEmpty) as Object

export const getAttributesFromPath = (attributes, path) => {
  const attrsPath = retrievePath(attributes, path)
  return attrsPath === '' ? attributes : get(attributes, attrsPath)
}

export const isRruleExpr = (schedule: string): boolean => {
  return !!schedule?.toLowerCase().includes('freq=')
}

export const retrievePath = (attributes, path) => {
  return path.reduce((result: string, level, index) => {
    const currentAttrs = get(attributes, result, attributes)
    const levelIndex = currentAttrs.findIndex(attr => attr.name === level)

    const isObjectArray =
      get(currentAttrs, `[${levelIndex}].type.type`) === 'array' &&
      get(currentAttrs, `[${levelIndex}].type.elementType.type`) === 'object'

    const prefix = index === 0 ? '' : '.'

    if (isObjectArray)
      result += `${prefix}[${levelIndex}].type.elementType.attributes`
    else result += `${prefix}[${levelIndex}].type.attributes`

    return result
  }, '')
}
export const getNewVersionSuggestionsForPlug = (
  fromVersion: string,
  existingVersions: PlugV0[] | PlugV2[],
  isV2 = false,
) => {
  const [latestVersion] = isV2
    ? getSortedPlugVersionsV2(existingVersions)
    : getSortedPlugVersions(existingVersions as PlugV0[])

  let newPatch = semver.inc(fromVersion, 'patch')
  let newMinor = semver.inc(fromVersion, 'minor')
  const newMajor = semver.inc(latestVersion, 'major')

  if (fromVersion !== latestVersion) {
    const versionNumbers = isV2
      ? existingVersions.map(data => data.plug.version)
      : existingVersions.map(version => version.version)
    const majorVersion = semver.major(fromVersion)
    const minorVersion = semver.minor(fromVersion)
    let matchedVersion = semver.maxSatisfying(
      versionNumbers,
      `${majorVersion}.${minorVersion}.*`,
    )

    newPatch = semver.inc(matchedVersion, 'patch')
    matchedVersion = semver.maxSatisfying(versionNumbers, `${majorVersion}.*`)
    newMinor = semver.inc(matchedVersion, 'minor')
  }

  return {
    newMajor,
    newMinor,
    newPatch,
  }
}

export const isBoolean = (value: any): boolean => {
  return typeof value === 'boolean' || BOOLEAN_VALUES_AS_STRINGS.includes(value)
}

export const isNumeric = (value: any): boolean => {
  return (
    typeof value === 'number' ||
    (!isNaN(parseFloat(value)) &&
      (/^[0-9]*$/.test(value) ||
        /^\d*\.?\d*$/.test(value) ||
        /^\d*,?\d*$/.test(value)))
  )
}

export const uriDecodeProps = (obj: Object): Object => {
  return Object.keys(obj).reduce((decodedObj, key) => {
    decodedObj[key] = decodeURIComponent(obj[key])
    return decodedObj
  }, {})
}

export const generatePlug = (
  name: string,
  version: string,
  author: string,
  data: PlugsV2Entity,
) => {
  return {
    name,
    version,
    type: data.plug.type,
    runtime: data.runtime.name,
    interface: data.plug.interface,
    metadata: {
      ...data.plug.metadata,
      author,
    },
  }
}

export const formatCompactNumber = (number: number): string => {
  if (number < 1000) {
    return number.toString()
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, '') + 'T'
  }
}

export function parseQueryParam(search: string, param: string) {
  const parsedQuery = querystring.parseUrl(search).query
  const searchParam = [].concat(parsedQuery?.[param])[0]

  return searchString.parse(searchParam || '')
}

export function sortByIgnoreCase(collection, key, ...args) {
  return sortBy(collection, [item => get(item, key).toLowerCase()], ...args)
}

export const getPeriodFromFrequency = (frequency: EFrequency) =>
  EXPORT_FREQUENCY_PERIODS[frequency]

// expression can be either CRON or RRULE
export const frequencyToText = (expression: string): string => {
  try {
    return RRule.fromString(expression).toText()
  } catch (_) {
    return null
  }
}

export const formatLicenseDate = (isoDate?: string): string => {
  return isoDate
    ? DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_FULL)
    : ''
}

export const formatLicenseLimit = (
  license: LicenseDetails,
  limitName: string,
): string => {
  if (!license) {
    // We don't have any license information available
    // => we consider the limit as being '0'
    return '0'
  } else if (isEmpty(license.metrics) || !license.metrics[limitName]?.limit) {
    // We have license information available but:
    // - no limits are provided or,
    // - the limit with specified name is not provided
    // => we consider the limit as 'Unlimited'
    return 'Unlimited'
  } else {
    return license.metrics[limitName].limit.toLocaleString()
  }
}

export const getDaysDiff = (endDate: string) => {
  return endDate
    ? Math.round(DateTime.fromISO(endDate).diffNow('days').days)
    : 0
}

export function createFileDownload(
  filename: string,
  contents: any,
  type = 'application/json',
) {
  const blob = new Blob([contents], { type })
  createBlobDownload(filename, blob)
}

export function createBlobDownload(filename: string, blob: Blob) {
  const url = URL.createObjectURL(blob)

  const dummyAnchortag = document.createElement('a')

  dummyAnchortag.href = url
  dummyAnchortag.target = '_blank'

  // target filename
  dummyAnchortag.download = filename

  dummyAnchortag.click()
}

export const formatXAxis = (value: number) => {
  return DateTime.fromMillis(value).toLocaleString(DateTime.DATE_MED)
}

export const formatYAxis = (value: number) => {
  return format('~s')(value)
}

export const formatSI = (value: number) => {
  return format('.3s')(value)
}

export const formatBytes = (value: number) => {
  return `${format('.3s')(value)}B`
}

export const labelFormatter = (value: number) => {
  return DateTime.fromMillis(value).toLocaleString(DateTime.DATE_HUGE)
}

export function createTitle(...string: any[]) {
  return string.filter(i => !isNil(i) && i !== '').join(' · ')
}

export const ensureTrailingSlash = (url: string): string => {
  if (!url.endsWith('/')) {
    return `${url}/`
  }

  return url
}
