import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Form } from '@waylay/react-components'
import { get, last } from 'lodash-es'
import { getTypeInput } from './getTypeInput'
import { ConstraintAttribute } from '~/components/ResourceConstraints/Constraints.types.'

const AttributesInput = ({
  attribute,
  placeholder,
}: {
  attribute: ConstraintAttribute
  placeholder: string
}) => {
  const type = get(attribute, 'type.type')
  const Input = getTypeInput(type)

  const { name } = attribute
  const label = last(name.split('.'))

  return (
    <Form.Field key={name}>
      <FormLabel htmlFor={name}>{label as ReactNode}</FormLabel>
      <Input
        name={name}
        placeholder={placeholder || label}
        attribute={attribute}
      />
    </Form.Field>
  )
}

const FormLabel = styled.label`
  margin-bottom: 0.5em;
`

export default AttributesInput
