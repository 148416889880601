import React from 'react'
import { css } from '@emotion/core'
import { Button, Modal, Segment } from '@waylay/react-components'
import { useModal } from 'react-modal-hook'
import { useToasts } from 'react-toast-notifications'
import { useNavigate } from 'react-router-dom'
import { get } from 'lodash-es'

import { TemplateContainer } from './useTemplate'

const useModalView = ({ id }) => {
  const { remove } = TemplateContainer.useContainer()
  const navigate = useNavigate()
  const { addToast } = useToasts()

  const onSubmit = () => {
    remove
      .mutateAsync({ id })
      .then(() => {
        hideModal()
        addToast(`Successfully deleted template ${id}`, {
          appearance: 'success',
        })
        navigate('/templates?sort=updatetime:desc')
      })
      .catch((error: any) => {
        hideModal()
        const message = get(error, 'response.data.error', error.message)
        addToast(`Failed to delete: ${message}`, { appearance: 'error' })
      })
  }

  const [showModal, hideModal] = useModal(
    () => (
      <Modal isOpen onRequestClose={hideModal}>
        <Segment.Group
          css={css`
            width: 600px;
          `}
        >
          <Segment.Header data-testid="header">Delete template</Segment.Header>
          <Segment data-testid="text">
            Deleting <strong>{id}</strong> is permanent. Are you sure you wish
            to continue?
          </Segment>
          <Modal.Actions>
            <Button
              data-testid="cancel-button"
              outline
              kind="secondary"
              onClick={hideModal}
            >
              Cancel
            </Button>
            <Button
              kind="danger"
              data-testid="delete-button"
              disabled={remove.isPending}
              loading={remove.isPending}
              onClick={() => onSubmit()}
            >
              Delete template
            </Button>
          </Modal.Actions>
        </Segment.Group>
      </Modal>
    ),
    [remove],
  )

  return [showModal, hideModal]
}

export default useModalView
