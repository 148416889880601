import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import {
  carbon,
  concrete,
  Modal,
  Popup,
  List,
  Segment,
  Select,
  Loader,
  Button,
  Tooltip,
} from '@waylay/react-components'
import { Link, useNavigate } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { useToasts } from 'react-toast-notifications'
import { get } from 'lodash-es'

import {
  IfFulfilled,
  IfPending,
  useMutateQueryPartialKey,
} from '../../Common/QueryHelpers'
import { useLogin } from '../../App/LoginContext'
import ProfileContainer from './useProfile'
import FakeImage from '../../Common/FakeImage'
import client from '~/lib/client'
import { PROFILE_KEY } from '~/lib/QueryKeys'

const Organisation = styled.div`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Name = styled.div`
  color: ${carbon};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`

const Details = styled.div`
  margin-left: 0.5em;
  flex: 1;
  min-width: 0; /* min-width flex ellipsis hack */
`

interface IProfileProps {
  collapsed?: boolean
}

const ProfileWrapper = styled.div`
  padding: 1em;
  display: flex;
  line-height: 1.25em;
  border-top: solid 1px ${concrete};
  width: 100%;
  outline: none;

  user-select: none;
  cursor: pointer;

  &:hover {
    background: #fff;
  }
`

const AvatarWrapper = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  overflow: hidden;
`

const Profile = ({ collapsed = false }: IProfileProps) => {
  const { token, logout } = useLogin()
  const state = ProfileContainer.useContainer()
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const useMutatePartialKey = useMutateQueryPartialKey()
  const [showModal, hideModal] = useModal(
    () => (
      <Modal isOpen onRequestClose={hideModal}>
        <Segment.Group style={{ width: '300px' }}>
          <Segment.Header>Switch Tenant</Segment.Header>
          <Segment>
            <Select
              placeholder="Select Tenant"
              options={state?.data?.switchableTenants.map(tenant => ({
                value: tenant.id,
                label: tenant.name,
              }))}
              onChange={option => {
                if (option?.value) {
                  handleTenantSelect(option.value)
                  hideModal()
                }
              }}
            />
          </Segment>
          <Modal.Actions>
            <Button kind="secondary" onClick={hideModal}>
              Close
            </Button>
          </Modal.Actions>
        </Segment.Group>
      </Modal>
    ),
    [state.data, token],
  )

  const handleTenantSelect = async (tenantId: string) => {
    try {
      const { token: exchangeToken, refresh_token: exchangeRefreshToken } =
        await client.tokens.exchange(token, tenantId)
      navigate(`/?token=${exchangeToken}&refresh_token=${exchangeRefreshToken}`)
    } catch (err) {
      console.error(err)
      addToast('Unable to switch tenant', { appearance: 'error' })
    }
  }

  useEffect(() => {
    useMutatePartialKey(PROFILE_KEY)
  }, [token])

  return (
    <div style={{ width: '100%' }}>
      <IfPending state={state} shouldRevalidate={false}>
        <ProfileWrapper
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader size={32} />
        </ProfileWrapper>
      </IfPending>
      <IfFulfilled state={state}>
        {({ organisation, fullName, switchableTenants }) => (
          <Popup
            placement="right"
            appendTo={() => document.body}
            content={
              <List as={Segment} padding={0} interactive>
                <List.Item nonInteractive>
                  Hello, <strong>{get(state, 'data.firstName')}</strong>
                </List.Item>
                <List.Divider />
                <List.Item as={Link} to="/administration/settings/details">
                  Your Account
                </List.Item>
                {switchableTenants && switchableTenants.length > 0 && (
                  <List.Item onClick={showModal}>Switch Tenant</List.Item>
                )}
                <List.Item onClick={() => logout({ withRedirect: false })}>
                  Sign Out
                </List.Item>
              </List>
            }
          >
            <ProfileWrapper>
              <>
                <AvatarWrapper>
                  <FakeImage title={fullName} />
                </AvatarWrapper>
                {!collapsed && (
                  <Details>
                    <Organisation>{organisation}</Organisation>
                    <Tooltip placement="top" content={fullName}>
                      <Name>{fullName}</Name>
                    </Tooltip>
                  </Details>
                )}
              </>
            </ProfileWrapper>
          </Popup>
        )}
      </IfFulfilled>
    </div>
  )
}

export default Profile
