import { useCallback } from 'react'
import styled from '@emotion/styled'
import { Button, Breadcrumb, Icon } from '@waylay/react-components'

import Refresh from './Refresh'
import SaveQuery from './SaveQuery'
import explorer from '~/components/Explore/ExplorerContainer'
import TimeWindowPicker from '~/components/Common/Date/TimeWindowPicker'
import GroupingPicker from '~/components/Explore/GroupingPicker'
import {
  TextLink,
  Container,
  Flexed,
  FlexedRow,
} from '~/components/Dashboard/Topbar/Topbar'
import { Title } from '~/components/Common/Topbar'

const Actions = styled.div`
  display: flex;
  & > * {
    margin-left: 0.5em;
  }
  align-items: center;
`

export default function ExploreTopbar() {
  const { updateConfig, zoomOut, queryConfig, queryName } =
    explorer.useContainer()
  const { window, until, from, grouping } = queryConfig

  const sections = [
    { content: <TextLink to="/explore">Explore</TextLink>, key: 'explore' },
    { content: queryName, key: 'exploreDataQuery' },
  ]

  // This might seem somewhat useless, but I dislike passing a context function
  // directly to an onChange handler, this makes it a bit more obvious how the data flows
  // from the component to the React Context
  const handleTimeWindowChange = useCallback(({ from, until, window }) => {
    updateConfig({ from, until, window })
  }, [])

  const handleGroupingChange = useCallback(grouping => {
    updateConfig({ grouping })
  }, [])

  return (
    <Container>
      <Flexed>
        <FlexedRow>
          {queryName ? (
            <Breadcrumb sections={sections} />
          ) : (
            <Title>Explore</Title>
          )}
        </FlexedRow>
        <Actions>
          <GroupingPicker grouping={grouping} onChange={handleGroupingChange} />
          <TimeWindowPicker
            from={from}
            until={until}
            window={window}
            onChange={handleTimeWindowChange}
            isExplorer
          />
          <Button outline kind="secondary" onClick={() => zoomOut()}>
            <Icon name="zoom_out" />
          </Button>
          <Refresh />
          <SaveQuery />
        </Actions>
      </Flexed>
    </Container>
  )
}
