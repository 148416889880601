import { createContainer } from 'unstated-next'
import { useToasts } from 'react-toast-notifications'
import { ISetting } from '../../Settings.types'
import client from '../../../../lib/client'
import {
  ADD_VAULT_KEY,
  REMOVE_VAULT_KEY,
  REVEAL_VAULT_KEY,
  VAULT_SETTINGS_KEY,
} from '~/lib/QueryKeys'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

const fetchVaultKeys = (): Promise<ISetting[]> => {
  return client.vault.keys() as Promise<ISetting[]>
}

const fetchVaultSetting = async (arg: { key: string }): Promise<ISetting> => {
  const { key } = arg
  const value = (await client.vault.get(key)) as Promise<ISetting>
  return {
    key,
    value: typeof value === 'string' ? value : JSON.stringify(value),
  }
}

const addVaultKeyFn = async (arg: { key: string; value: string }) => {
  const { key, value } = arg
  await client.vault.set(key, value)
  return { key, value }
}

const removeVaultKeyFn = async (arg: { key: string }) => {
  const { key } = arg
  await client.vault.remove(key)
  return { key }
}

export const useVault = () => {
  const { addToast } = useToasts()

  const queryClient = useQueryClient()
  const vault = useQuery({
    queryKey: [VAULT_SETTINGS_KEY],
    queryFn: fetchVaultKeys,
  })

  const addVaultKey = useMutation({
    mutationFn: addVaultKeyFn,
    onSuccess: ({ key }) => {
      const index = vault?.data.findIndex(pair => pair.key === key)
      const clone = [...vault.data]

      index === -1 ? clone.push({ key }) : (clone[index] = { key })

      queryClient.setQueryData([VAULT_SETTINGS_KEY], clone)
      addToast('Successfully saved setting', { appearance: 'success' })
    },
    onError: () => {
      addToast('Failed to save setting', { appearance: 'error' })
    },
    throwOnError: false,
  })

  const removeVaultKey = useMutation({
    mutationFn: removeVaultKeyFn,
    onSuccess: () => {
      vault.refetch()
      addToast('Successfully removed setting', { appearance: 'success' })
    },
    onError: () => {
      addToast('Failed to remove setting', { appearance: 'error' })
    },
    throwOnError: false,
  })

  const revealVaultValue = useMutation({
    mutationFn: fetchVaultSetting,
    onSuccess: ({ key, value }) => {
      const newData =
        vault?.data?.length > 0
          ? vault?.data?.map(item => {
              return item.key === key ? { ...item, value } : item
            })
          : []
      queryClient.setQueryData([VAULT_SETTINGS_KEY], newData)
    },
  })

  return {
    vault,
    addVaultKey,
    removeVaultKey,
    revealVaultValue,
  }
}

export default useVault
export const VaultContainer = createContainer(useVault)
