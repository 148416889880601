import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import {
  colors,
  concrete,
  Select,
  Input,
  Loader,
} from '@waylay/react-components'
import React, { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { ImportType, OpenApiContainer } from './useUploadOpenApiPlug'
import { OpenAPIV3 } from 'openapi-types'
import Box, { BoxType } from './Box'
import { isEmpty } from 'lodash-es'

interface IOption {
  value: string
  label: string
}

const CenteredLoader = styled.div`
  margin: auto;
  width: 13%;
  padding: 10px;
`

const SubTitle = styled.div`
  font-size: 1em;
  padding: 10px 15px 10px 15px;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2em;
`

const Preview = styled(Title)`
  font-weight: bold;
  font-size: 1.2em;
  padding: 20px 20px 20px 20px;
`

const RowFlexBox = styled.div`
  display: flex;
  flex-direction: row;
`

const ColumnFlexBox = styled.div`
  display: flex;
  flex-direction: column;
`

const FormLayout = styled(ColumnFlexBox)`
  width: 35%;
  overflow-y: scroll;
  background-color: #fafafa;
  gap: 15px;
  padding: 20px 20px 20px 20px;
  border-right: 1px solid ${colors.withWeight(concrete, 500)};
`

const TwoColumnsLayout = styled(RowFlexBox)`
  width: 100%;
  height: 80vh;
`

const RowInput = styled(RowFlexBox)`
  width: 100%;
  justify-content: center;
  align-items: center;
`
const SwaggerUIWrapper = styled(ColumnFlexBox)`
  width: 65%;
  overflow-y: scroll;
`

const DropDownBox = styled(ColumnFlexBox)`
  gap: 5px;
`
const Mandatory = styled.div`
  color: red;
  margin-left: 3px;
`

const IGNORABLE_OPERATIONS = ['parameters']

const LabelAndDropDown = ({
  name,
  options,
  mandatory,
}: {
  name: string
  options: string[]
  mandatory: boolean
}) => {
  const { plug, setPlug } = OpenApiContainer.useContainer()

  const property = name.toLowerCase()

  const onChange = (value: IOption) => {
    if (property === 'path') {
      setPlug({ path: value.value, name: plug.name })
    } else {
      setPlug(prev => ({
        ...prev,
        [property]:
          property === 'security'
            ? value.value.substring(0, value.value.indexOf('(') - 1)
            : value.value,
      }))
    }
  }

  return (
    <DropDownBox>
      <RowFlexBox>
        <div data-testid={`${property}-title`}>{name}</div>
        {mandatory && (
          <Mandatory data-testid={`${property}-mandatory`}>*</Mandatory>
        )}
      </RowFlexBox>
      <div data-testid={`${property}-select`}>
        <Select
          options={options.map(option => ({ label: option, value: option }))}
          onChange={event => onChange(event)}
          value={
            plug[property]
              ? {
                  label: plug[property],
                  value: plug[property],
                }
              : null
          }
        />
      </div>
    </DropDownBox>
  )
}

const Configuration = () => {
  const {
    importType,
    url,
    file,
    api,
    plug,
    setPlug,
    setPlugNameAvailability,
    isPlugNameAllowed,
    isInCreation,
    generatePlugError,
  } = OpenApiContainer.useContainer()

  const paths = useMemo(() => (api.paths ? Object.keys(api.paths) : []), [api])

  const operations = useMemo(
    () =>
      api.paths[plug.path]
        ? Object.keys(api.paths[plug.path]).filter(
            key => !IGNORABLE_OPERATIONS.includes(key),
          )
        : [],
    [plug.path],
  )

  const servers = useMemo(
    () =>
      (api as OpenAPIV3.Document)?.servers
        ? (api as OpenAPIV3.Document)?.servers?.map(server => {
            try {
              const serverUrl = new URL(server.url)
              return serverUrl.href
            } catch (err) {
              try {
                return `${new URL(url).origin}${server.url}`
              } catch {
                return ''
              }
            }
          })
        : [],
    [api],
  )

  const generateOauth2FlowsList = () => {
    const security = (api as OpenAPIV3.Document)?.components?.securitySchemes?.[
      plug.security
    ]
    if ((security as any)?.type === 'oauth2') {
      return Object.keys((security as any).flows)
    }
    return []
  }

  const oauth2Flows = useMemo(() => generateOauth2FlowsList(), [plug.security])

  const constructSchema = (api: OpenAPIV3.Document) => {
    return Object.keys(api.components.securitySchemes).map(
      key => `${key} (${(api.components.securitySchemes[key] as any)?.type})`,
    )
  }

  const securitySchemas = useMemo(
    () =>
      !isEmpty(
        (api as OpenAPIV3.Document)?.paths?.[plug.path]?.[plug.operation]
          ?.security,
      )
        ? constructSchema(api as OpenAPIV3.Document)
        : (api as OpenAPIV3.Document)?.components?.securitySchemes
        ? Object.keys(
            (api as OpenAPIV3.Document).components.securitySchemes,
          ).map(
            key =>
              `${key} (${
                (
                  (api as OpenAPIV3.Document).components.securitySchemes[
                    key
                  ] as any
                )?.type
              })`,
          )
        : [],
    [plug.operation, api, constructSchema],
  )

  useEffect(() => {
    const plugNameAvailability = async (plugName: string) => {
      await setPlugNameAvailability(plugName)
    }
    const newPlugName = api.paths?.[plug.path]?.[plug.operation]?.operationId
      ? api.paths[plug.path][plug.operation].operationId
      : ''

    setPlug(prev => ({
      ...prev,
      name: newPlugName,
    }))
    plugNameAvailability(newPlugName)
  }, [plug.operation])

  const checkPlugName = async (plugName: string) => {
    setPlug(prev => ({
      ...prev,
      name: plugName,
    }))
    await setPlugNameAvailability(plugName)
  }

  return (
    <>
      {isInCreation ? (
        <>
          <SubTitle>Please wait.. </SubTitle>
          <CenteredLoader>
            <Loader size={54} thickness={4} />
          </CenteredLoader>
        </>
      ) : (
        <TwoColumnsLayout>
          <FormLayout>
            <Title data-testid="configuration-title">Configuration</Title>
            <RowInput>
              <div data-testid="name-label">Name</div>
              <Mandatory data-testid="mandatory-name">*</Mandatory>
              <Input.Group style={{ width: '100%' }}>
                <Input
                  data-testid="name-input"
                  style={{ width: '100%', marginLeft: '10px' }}
                  placeholder="Name"
                  value={plug.name}
                  onChange={(event: { target: { value: string } }) =>
                    checkPlugName(event.target.value)
                  }
                />
              </Input.Group>
            </RowInput>

            {!isPlugNameAllowed && (
              <Box
                type={BoxType.error}
                text="Plugin name is already taken"
                title="Error"
              />
            )}
            <LabelAndDropDown name="Path" options={paths} mandatory />
            {plug.path && (
              <LabelAndDropDown
                name="Operation"
                options={operations}
                mandatory
              />
            )}
            <LabelAndDropDown
              name="Server"
              options={servers}
              mandatory={false}
            />
            <LabelAndDropDown
              name="Security"
              options={securitySchemas}
              mandatory={false}
            />
            {!isEmpty(oauth2Flows) && (
              <LabelAndDropDown
                name="Flow"
                options={oauth2Flows}
                mandatory={false}
              />
            )}
            {!isEmpty(generatePlugError) && (
              <Box
                type={BoxType.error}
                text={generatePlugError}
                title="Error"
              />
            )}
          </FormLayout>
          <SwaggerUIWrapper>
            <Preview>Preview</Preview>
            {importType === ImportType.File ? (
              <SwaggerUI spec={file} />
            ) : (
              <SwaggerUI url={url} />
            )}
          </SwaggerUIWrapper>
        </TwoColumnsLayout>
      )}
    </>
  )
}

export default Configuration
