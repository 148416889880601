// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.monaco-editor-container {
  border: 1px solid #e6e6e6;
  /* Default border color */
}

.monaco-editor-container-error {
  border: 1px solid red;
  /* Error border color */
}`, "",{"version":3,"sources":["webpack://./src/components/Tasks/Editor/PropertyFields/MonacoEditor.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":[".monaco-editor-container {\n  border: 1px solid #e6e6e6;\n  /* Default border color */\n}\n\n.monaco-editor-container-error {\n  border: 1px solid red;\n  /* Error border color */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
