import styled from '@emotion/styled'
import { colors, concrete } from '@waylay/react-components'

export const GeneralItem = styled.div`
  display: flex;
  line-height: 1.5em;
  box-sizing: border-box;
  &:hover {
    background: ${colors.withWeight(concrete, 100)};
  }

  & + & {
    border-top: solid 1px ${colors.withWeight(concrete, 500)};
  }
`
