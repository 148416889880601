import React from 'react'
import { IPropertyInput } from '../../types'
import EditorFieldContainer from './EditorFieldContainer'

const ObjectField = (props: IPropertyInput) => {
  const { setFieldValue, ...rest } = props
  const handleChange = (name, value) => {
    let parsedValue
    try {
      parsedValue = JSON.parse(value)
    } catch (e) {
      parsedValue = value
    }
    setFieldValue(name, parsedValue)
  }
  return (
    <EditorFieldContainer
      {...rest}
      setFieldValue={handleChange}
      language="json"
    />
  )
}

export default ObjectField
