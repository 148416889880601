import React from 'react'
import VaultSelect from '~/components/Common/VaultSelect'
import { IPropertyInput } from '../../types'

const VaultField = ({
  property,
  value,
  setFieldValue,
  isError,
}: IPropertyInput) => {
  const { name, defaultValue } = property

  const handleChange = event => {
    setFieldValue(name, event?.value ?? '')
  }

  return (
    <VaultSelect
      value={value}
      onChange={handleChange}
      placeholder={defaultValue}
      isError={isError}
    />
  )
}

export default VaultField
