import React, { useEffect, useState } from 'react'
import { Select } from '@waylay/react-components'
import { RuntimeContainer } from '../../useRuntimes'
import { isArray } from 'lodash-es'

export interface IRuntimeSelection {
  value: string
  label: string
}

interface RuntimeSelectionProps {
  labelVisibility?: boolean
  setRuntime: (value: { name: string; label: string; version: string }) => void
  isNew?: boolean
  defaultValue?: string
  runtimeLabel?: string
  readOnly?: boolean
  runtime?: {
    label: string
    name?: string
    version?: string
    title?: string
  }
  isModal?: boolean
}

const RuntimeSelection: React.FC<RuntimeSelectionProps> = ({
  labelVisibility,
  setRuntime,
  isNew,
  runtimeLabel = null,
  readOnly,
  runtime,
  isModal,
}) => {
  const {
    majorRuntimes,
    allRuntimes,
    loading,
    callbackRuntimesMap,
    nonCallbackRuntimesMap,
  } = RuntimeContainer.useContainer()

  const isPlugin = window.location.pathname.includes('plugins')
  const [isChecked, setIsChecked] = useState(false)
  const runtimesToUse = isChecked ? callbackRuntimesMap : nonCallbackRuntimesMap

  function mapRuntimes(runtimes: any[]): any[] {
    return Array.isArray(runtimes) && runtimes.length > 0
      ? runtimes.map(runtime => ({
          label: runtime.title,
          value: `${runtime.name}-${runtime.version}`,
          runtime: runtime.name,
          version: runtime.version,
        }))
      : []
  }

  const getOptions = () => {
    if (isNew) {
      if (isPlugin && isModal) {
        return mapRuntimes(runtimesToUse)
      } else {
        return mapRuntimes(majorRuntimes)
      }
    } else {
      const filteredAllRuntimes = []
      allRuntimes?.forEach(({ name, title, version }) => {
        if (runtime.name && name === runtime.name) {
          filteredAllRuntimes.push({
            label: title.replace(' Plug', ''),
            value: `${name}-${version}`,
            runtime: name,
            version: version,
          })
        }
      })
      if (runtime) {
        const notInList =
          filteredAllRuntimes?.find(
            runtimeItem =>
              runtimeItem.runtime === runtime?.name &&
              runtimeItem.version === runtime?.version,
          ) === undefined

        if (notInList && runtime) {
          filteredAllRuntimes.push({
            label: runtime.title.replace(' Plug', ''),
            value: `${runtime.name}-${runtime.version}`,
            runtime: runtime.name,
            version: runtime.version,
          })
        }
      }

      return filteredAllRuntimes
    }
  }

  useEffect(() => {
    getOptions()
  }, [isChecked, getOptions])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
    const runtimes = event.target.checked
      ? callbackRuntimesMap
      : nonCallbackRuntimesMap
    const selectRuntime = runtimes[0]
    setRuntime({
      label: selectRuntime.title,
      name: selectRuntime.name,
      version: selectRuntime.version,
    })
  }

  return (
    <>
      {labelVisibility && <label htmlFor="runtime">Runtime</label>}
      {isPlugin && isModal && (
        <div
          style={{
            display: 'flex',
            paddingBottom: '10px',
          }}
        >
          <input
            style={{ height: '20px', marginRight: '5px' }}
            role="checkbox"
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label style={{ margin: '0' }} htmlFor="runtime">
            Use Callback
          </label>
        </div>
      )}

      {!loading && (
        <>
          <Select
            isDisabled={readOnly}
            name="runtime"
            placeholder="select runtime"
            id="runtime"
            options={getOptions()}
            value={{
              label: runtimeLabel,
              value: `${runtime.name}-${runtime.version}`,
              version: runtime.version,
            }}
            onChange={option => {
              setRuntime({
                name: option.runtime,
                label: option.label,
                version: option.version,
              })
            }}
          />
        </>
      )}
    </>
  )
}

export default RuntimeSelection
