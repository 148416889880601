export interface IStorageBucket {
  _links: {
    self: {
      href: string
    }
    // eslint-disable-next-line camelcase
    sign_post: {
      href: string
    }
    remove: {
      href: string
      method: string
    }
    subscriptions: {
      href: string
    }
  }
  alias: string
  name: string
  store: string
}

export interface IStorageFolder {
  _links: {
    // eslint-disable-next-line camelcase
    sign_post: {
      href: string
    }
    remove: {
      href: string
      method: string
    }
  }
  name: string
  // eslint-disable-next-line camelcase
  is_dir: boolean
}

export interface IStorageFile {
  _links: {
    remove: {
      href: string
      method: string
    }
    get?: {
      href: string
    }
    // eslint-disable-next-line camelcase
    sign_get?: {
      href: string
    }
    // eslint-disable-next-line camelcase
    sign_put?: {
      href: string
    }
  }
  name: string
  size: number // in bytes
  // eslint-disable-next-line camelcase
  last_modified: string
  // eslint-disable-next-line camelcase
  is_dir: boolean
}

export interface IStorageSubscription {
  id: string
  title?: string
  description?: string
  channel: { name: string }
  filters: IFilter[]
  _links: {
    create: {
      href: string
      method: string
    }
  }
}

export interface IFilter {
  prefix: string
  suffix: string
  onFileChange: boolean
  onFileDelete: boolean
  events?: string[]
}

export enum EMode {
  add = 'add',
  edit = 'edit',
}
