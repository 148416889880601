import React, { useEffect, useState } from 'react'
import { Message } from '@waylay/react-components'
import {
  LicenseAlarmType,
  LicenseMetricsMapping,
  ELicenseAlarmLocation,
} from '~/lib/types'
import { LicenseContainer } from '~/hooks/useLicense'
import { LicenseDetails } from '@waylay/client/dist/auth/license'
import { formatLicenseDate, getDaysDiff } from '~/lib/util'

const generateMessage = (
  location: ELicenseAlarmLocation,
  licenseAlarm: LicenseAlarmType,
  license: LicenseDetails,
  breachedMetrics: any,
) => {
  const expirationDateFormatted = license?.end
    ? formatLicenseDate(license?.end)
    : ''

  const daysDiff = getDaysDiff(license?.end)
  if (location === ELicenseAlarmLocation.GENERAL) {
    switch (licenseAlarm) {
      case LicenseAlarmType.EXPIRES_SOON:
        return {
          title: 'License is about to expire',
          description: `Your Waylay platform license is about to expire in ${daysDiff} days, on ${expirationDateFormatted}. After this date, access to certain platform features like new task creation, resource creation or adding new data ingestion streams will be suspended. Please contact Waylay support or your platform administrator for a license update.`,
        }
      case LicenseAlarmType.EXCEEDING_LIMITS:
        return {
          title: 'License limits exceeded',
          description:
            'Your Waylay platform use has exceeded the volume covered by the current license and the access to certain platform features has been suspended. Please contact Waylay support or your platform administrator for a license update that restores this functionality.',
        }
      case LicenseAlarmType.EXPIRED_OR_MISSING:
        return {
          title: 'License is expired or missing',
          description: `Your Waylay platform license ${
            license?.end
              ? `has expired on ${expirationDateFormatted}`
              : 'could not be found'
          }. Certain platform features like new task creation, resource creation or adding new data ingestion streams have be disabled. Please contact Waylay support or your platform administrator for a license update to restore this functionality.`,
        }
    }
  }
  if (location === ELicenseAlarmLocation.SPECIFIC_TAB) {
    let breachedMetricsMessage = ''
    if (breachedMetrics?.length > 0) {
      breachedMetrics?.forEach((metric, index) => {
        breachedMetricsMessage = breachedMetricsMessage.concat(
          ` ${license?.metrics?.[metric.name].limit} ${
            LicenseMetricsMapping[metric.name]
          }${index !== breachedMetrics.length - 1 ? ',' : '.'}`,
        )
      })
    }
    switch (licenseAlarm) {
      case LicenseAlarmType.EXPIRED_OR_MISSING:
        return {
          title: 'License is expired or missing',
          description: `Your Waylay platform license ${
            license?.end
              ? `has expired on ${expirationDateFormatted}`
              : 'is not present'
          } and access to this feature has been disabled. Please contact Waylay support or your platform administrator for a license update.`,
        }
      case LicenseAlarmType.EXCEEDING_LIMITS:
        return {
          title: 'License limits exceeded',
          description: `You have exceeded licensed volume of ${breachedMetricsMessage} Please contact Waylay support or your platform administrator for a license update.`,
        }
    }
  }
}

const LicenseAlarm = ({
  location,
  customStyle,
}: {
  location: ELicenseAlarmLocation
  customStyle?: any
}) => {
  const { licenseAlarm, license, breachedMetrics } =
    LicenseContainer.useContainer()

  const [message, setMessage] = useState<{
    title: string
    description: string
  }>()

  useEffect(() => {
    if (licenseAlarm) {
      setMessage(
        generateMessage(location, licenseAlarm, license, breachedMetrics),
      )
    }
  }, [location, licenseAlarm, breachedMetrics, license])

  const style =
    location === ELicenseAlarmLocation.GENERAL &&
    licenseAlarm === LicenseAlarmType.EXPIRES_SOON
      ? {
          backgroundColor: '#fff8e6',
          border: `2px solid #fac301`,
          color: '#fac301',
        }
      : {}

  const shouldDisplayMessage =
    (location === ELicenseAlarmLocation.GENERAL && licenseAlarm) ||
    (location === ELicenseAlarmLocation.SPECIFIC_TAB &&
      licenseAlarm &&
      licenseAlarm !== LicenseAlarmType.EXPIRES_SOON)

  return (
    <>
      {shouldDisplayMessage && (
        <Message
          data-testid="license-alarm"
          title={message?.title}
          kind="warning"
          style={{
            ...style,
            ...customStyle,
          }}
        >
          {message?.description}
        </Message>
      )}
    </>
  )
}

export default LicenseAlarm
