import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { OpenAPI } from 'openapi-types'
import { validateSpec } from '@waylay/oas-generator'
import client from '~/lib/client'
import { isEmpty } from 'lodash-es'
import { ContentType } from '@waylay/oas-generator/dist/lib/types'
import { Plug } from '@waylay/client/dist/plugs'

export enum ImportType {
  File,
  Url,
}

interface IGeneratedPlug {
  name?: string
  path?: string
  operation?: string
  server?: string
  security?: string
  flow?: string
}

const EMPTY_STRING = ''

const useUploadOpenApiPlug = () => {
  const [importType, setImportType] = useState<ImportType>(ImportType.File)
  const [url, setUrl] = useState<string>(EMPTY_STRING)
  const [file, setFile] = useState<string>(EMPTY_STRING)
  const [fileName, setFileName] = useState<string>(EMPTY_STRING)
  const [validationError, setValidationError] = useState<string>(null)
  const [api, setApi] = useState<OpenAPI.Document>(null)
  const [plug, setPlug] = useState<IGeneratedPlug>({})
  const [isPlugNameAllowed, setIsPlugNameAllowed] = useState<boolean>(true)
  const [generatePlugError, setGeneratePlugError] = useState<string>('')
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [isInCreation, setIsInCreation] = useState<boolean>(false)

  useEffect(() => {
    if (!isEmpty(api) && !isDirty) {
      setIsDirty(true)
    }
  }, [file])

  useEffect(() => {
    if (!isEmpty(api) && !isDirty) {
      setIsDirty(true)
    }
  }, [url])

  useEffect(() => {
    if (generatePlugError) {
      setGeneratePlugError('')
    }
  }, [plug])

  const validateApi = async (spec: string) => {
    try {
      const fileType =
        importType === ImportType.File
          ? fileName.includes('.json')
            ? ContentType.JSON
            : fileName.includes('.yaml')
            ? ContentType.YAML
            : ContentType.UNKNOWN
          : undefined
      const api = await validateSpec(spec, fileType)

      setApi(api)
      setValidationError('')
      setIsDirty(false)
    } catch (err) {
      throw new Error(err)
    }
  }

  const setPlugNameAvailability = async (plugName: string) => {
    const plug = (await client.sensors.list({
      name: plugName,
      includeDeprecated: true,
    })) as Plug[]
    setIsPlugNameAllowed(plug.length === 0)
    // return plug.length === 0 ? true : false
  }

  useEffect(() => {
    setPlug({})
  }, [api])

  useEffect(() => {
    setValidationError(null)
    if (importType === ImportType.Url) {
      setFile('')
    } else {
      setUrl('')
    }
    setApi(null)
    setFileName('')
    setGeneratePlugError('')
  }, [importType])

  return {
    url,
    setUrl,
    importType,
    setImportType,
    file,
    setFile,
    api,
    setApi,
    fileName,
    setFileName,
    validationError,
    setValidationError,
    plug,
    setPlug,
    validateApi,
    setPlugNameAvailability,
    isPlugNameAllowed,
    generatePlugError,
    setGeneratePlugError,
    isDirty,
    setIsInCreation,
    isInCreation,
  }
}

export const OpenApiContainer = createContainer(useUploadOpenApiPlug)
