import React from 'react'
import {
  ArrayInput,
  BooleanInput,
  EnumInput,
  NumberInput,
  ObjectInput,
  ResourceRefInput,
  StringInput,
} from './Inputs'
import { EConstraintType } from '~/components/ResourceConstraints/Constraints.types.'

interface IAbstractInput {
  name: string
  placeholder?: string
  attribute?: any
}

export const getTypeInput = (
  type: EConstraintType,
): React.FC<IAbstractInput> => {
  switch (type) {
    case EConstraintType.string:
      return StringInput
    case EConstraintType.numeric:
      return NumberInput
    case EConstraintType.boolean:
      return BooleanInput
    case EConstraintType.enum:
      return EnumInput
    case EConstraintType.resourceRef:
      return ResourceRefInput
    case EConstraintType.object:
      return ObjectInput
    case EConstraintType.array:
      return ArrayInput
    default:
      return StringInput
  }
}
