/* eslint-disable @typescript-eslint/no-misused-promises */
import React, {
  forwardRef,
  useRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import MonacoEditor, {
  monaco,
  ChangeHandler,
  EditorWillMount,
} from 'react-monaco-editor'
import styled from '@emotion/styled'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import loadable from '@loadable/component'
import { isEqual } from 'lodash-es'
import { usePrevious } from 'react-delta'

import extendDeclarativeBinding from './languages/declarative-binding'
import extendWaylayTheme from './themes/waylay-extra'
import EditorStatusBar from './EditorStatusBar'
import waylayTypes from './typings/waylayClientTypes'
import waylayUtilTypes from './typings/waylayUtilTypes'
import { waylayClient, waylayUtil, injectOptions } from './injectables/globals'
import { IPlugProperty } from '~/lib/types'

interface IEditorProvider {
  useContainer: () => {
    setStateProperty: (property: string, value: any) => void
  }
}
interface CodeEditorProps {
  script?: string
  supportedLanguages?: string[]
  // any context useful for the editor
  context?: {
    declarativeBindingSchema?: object
    properties?: IPlugProperty[]
    EditorProvider?: IEditorProvider
  }
  onChange?: ChangeHandler
  onChangeValue?: (value: string) => void
  className?: string
  showStatusBar?: boolean
  isWebscript?: boolean
  shouldAddGlobalWaylayClient?: boolean
  readOnly?: boolean
}

const MonacoVim = loadable.lib(async () => await import('monaco-vim'))

type IStandaloneCodeEditor = MonacoEditor['editor']

const EDITOR_CONFIG = {
  automaticLayout: false, // we use the AutoSize plugin since it seems more reliable
  ariaLabel: 'plugin script editor',
  codeLens: false,
  lightbulb: { enabled: false },
  minimap: { enabled: false },
  scrollBeyondLastLine: false,
  rulers: [120],
  folding: false,
  tabSize: 2,
  showUnused: true,
  showDeprecated: true,
}

const DEFAULT_EDITOR_SETTINGS = {
  useDarkMode: false,
  useVimMode: false,
  useRelativeLineNumbers: false,
  wordWrap: 'on',
}

export const EDITOR_SETTINGS_MAP = {
  useDarkMode: 'Dark mode',
  useVimMode: 'Vim mode',
  useRelativeLineNumbers: 'Relative line numbers',
}

export const DEFAULT_SUPPORTED_LANGUAGES = [
  'declarative-binding',
  'javascript',
  'json',
  'python',
]

const shouldUpdateSchema = (editor, currentSchema, prevSchema) => {
  const hasDeclarativeBinding = editor.languages
    .getLanguages()
    .some(lang => lang.id === 'declarative-binding')
  const hasDifferentSchema = isEqual(currentSchema, prevSchema)

  return !hasDeclarativeBinding || hasDifferentSchema
}

const CodeEditor = forwardRef(function Editor(
  props: CodeEditorProps,
  ref: React.MutableRefObject<MonacoEditor>,
) {
  const {
    isWebscript = true,
    readOnly = false,
    script = '',
    supportedLanguages = DEFAULT_SUPPORTED_LANGUAGES,
    context = {},
    showStatusBar = true,
    shouldAddGlobalWaylayClient = false,
  } = props

  const EditorProvider: IEditorProvider = context.EditorProvider || {
    useContainer: () => ({ setStateProperty: () => {} }),
  }

  const { setStateProperty: setPluginsStateProperty } =
    EditorProvider.useContainer()
  const { declarativeBindingSchema, properties } = context // any context that can be used in the editor.
  const prevSchema = usePrevious(declarativeBindingSchema)

  const [loadedEditorSettings] = useLocalStorage(
    'editorSettings',
    DEFAULT_EDITOR_SETTINGS,
  )
  const [editorSettings, setEditorSettings] = useState(loadedEditorSettings)
  const [selectedLanguage, setSelectedLanguage] = useState(
    supportedLanguages[0],
  )

  useEffect(() => {
    if (supportedLanguages?.length > 0) {
      setSelectedLanguage(supportedLanguages[0])
    }
  }, [supportedLanguages])

  const vimModeAdapter = useRef(null)
  const vimStatusLineRef = useRef(null)
  const itemProviderRef = useRef(null)
  let globalOptionsRef = null

  const [code, setCode] = useState(script)

  // focus editor and set initial script value,
  // make sure we only do this exactly once
  const initEditor = (editor: IStandaloneCodeEditor) => {
    updateEditorSettings(editor)

    editor.focus()
    editor.setValue(code)
  }

  const updateEditorSettings = async (editor: IStandaloneCodeEditor) => {
    editor = editor || ref.current.editor

    // Write new settings
    writeStorage('editorSettings', editorSettings)

    // Update editor options
    editor.updateOptions({
      ...EDITOR_CONFIG,
      readOnly,
      // The lineNumbers option default for 'normal' line numbers is 'on', see:
      // https://microsoft.github.io/monaco-editor/api/modules/monaco.editor.html#linenumberstype
      lineNumbers: editorSettings.useRelativeLineNumbers ? 'relative' : 'on', // : num => String(num - 1),
      wordWrap: 'on',
    })

    // Init vim mode
    if (editorSettings.useVimMode && !vimModeAdapter.current) {
      MonacoVim.load().then(monacoVim => {
        vimModeAdapter.current = monacoVim.initVimMode(
          editor,
          vimStatusLineRef.current,
        )
      })
    } else if (!editorSettings.useVimMode && vimModeAdapter.current) {
      vimModeAdapter.current.dispose()
      vimModeAdapter.current = null
    }

    // Adds autocompletion for options
    if (!isWebscript && monaco) {
      if (globalOptionsRef) globalOptionsRef.dispose()
      globalOptionsRef =
        monaco.languages.typescript.javascriptDefaults.addExtraLib(
          injectOptions(properties),
          'local://global-options',
        )
    }
  }

  const toggleSetting = (settingName: string) => {
    setEditorSettings(oldSettings => ({
      ...oldSettings,
      [settingName]: !oldSettings[settingName],
    }))
  }

  useEffect(() => {
    if (ref.current) {
      updateEditorSettings(ref.current.editor)
    }
  }, [editorSettings, properties, ref.current, readOnly])

  const onChange = useCallback(
    (newValue, event) => {
      setCode(newValue)
      if (!isWebscript) setPluginsStateProperty('updatedScript', newValue)
      if (typeof props.onChange === 'function') {
        props.onChange(newValue, event)
      }
      if (typeof props.onChangeValue === 'function') {
        props.onChangeValue(newValue)
      }
    },
    [props.onChange, props.onChangeValue],
  )

  const editorWillMount: EditorWillMount = useCallback(monaco => {
    const typescript = monaco.languages.typescript

    typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true, // setting this to false will enable linting, but there are a LOT of globals we need to define...
      noSuggestionDiagnostics: true, // disable suggestions like "add type annotation"
      noSyntaxValidation: false,
    })

    typescript.javascriptDefaults.setCompilerOptions({
      lib: ['ES2019'],
      module: typescript.ModuleKind.CommonJS,
      moduleResolution: typescript.ModuleResolutionKind.NodeJs,
      allowNonTsExtensions: true,
    })

    if (shouldAddGlobalWaylayClient) {
      typescript.javascriptDefaults.addExtraLib(waylayTypes)
      typescript.javascriptDefaults.addExtraLib(waylayClient)
      typescript.javascriptDefaults.addExtraLib(waylayUtilTypes)
      typescript.javascriptDefaults.addExtraLib(waylayUtil)
    }

    // add waylay theme
    extendWaylayTheme(monaco)

    // add declarative-binding language if not already initialized
    if (shouldUpdateSchema(monaco, declarativeBindingSchema, prevSchema)) {
      if (itemProviderRef.current) {
        itemProviderRef.current.dispose()
      }
      itemProviderRef.current = extendDeclarativeBinding(
        monaco,
        declarativeBindingSchema,
      )
    }
  }, [])

  useEffect(() => {
    return () => {
      if (itemProviderRef.current) {
        itemProviderRef.current.dispose()
        itemProviderRef.current = null
      }
    }
  }, [])

  return (
    <FlexWrapper className={props.className}>
      <EditorFlexChild>
        <AutoSizer>
          {({ height, width }) => (
            <>
              <MonacoEditor
                key={selectedLanguage}
                height={height}
                width={width}
                editorDidMount={initEditor}
                language={selectedLanguage}
                options={EDITOR_CONFIG}
                onChange={onChange}
                ref={ref}
                theme={
                  readOnly
                    ? 'waylay-read-only'
                    : editorSettings.useDarkMode
                    ? 'waylay-dark'
                    : 'waylay-light'
                }
                editorWillMount={editorWillMount}
                {...props}
              />
            </>
          )}
        </AutoSizer>
      </EditorFlexChild>
      {showStatusBar && (
        <EditorStatusBar
          vimStatusLineRef={vimStatusLineRef}
          editorSettings={editorSettings}
          toggleSetting={toggleSetting}
          supportedLanguages={supportedLanguages}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      )}
    </FlexWrapper>
  )
})

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
`

const EditorFlexChild = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`

export default CodeEditor
