import { useMemo } from 'react'
import querystring from 'query-string'
import { useNavigate, useLocation } from 'react-router-dom'
import { createContainer } from 'unstated-next'
import { isEmpty } from 'lodash-es'

interface ParsedQuery {
  [key: string]: string | null | undefined
}

const useQueryString = () => {
  const navigate = useNavigate()
  const { search = '', pathname = '' } = useLocation()

  const query = useMemo(() => parseQuery(search), [search])

  const setQueryValue = (key: string, value = '') => {
    const search = querystring.stringify({
      ...query,
      [key]: value,
    })

    navigate({ search }, { replace: true })
  }

  const setQueryObject = (values: object) => {
    let search = ''
    if (pathname.includes('tasks') && pathname.includes('logs')) {
      if (!isEmpty(values)) {
        search = querystring.stringify({
          ...values,
        })
      }
    } else {
      search = querystring.stringify({
        ...query,
        ...values,
      })
    }

    navigate({ search }, { replace: true })
  }

  return {
    query,
    setQueryValue,
    setQueryObject,
  }
}

function parseQuery(search: string): ParsedQuery {
  return querystring.parse(search, {
    decode: false,
    arrayFormat: 'comma',
  }) as ParsedQuery
}

export default createContainer(useQueryString)

export { parseQuery }
