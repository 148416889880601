import { css } from '@emotion/core'
import { colors, carbon, Typography } from '@waylay/react-components'

export const Global = css`
  .react-toast-notifications__container {
    z-index: 100000 !important;
  }
  * {
    box-sizing: border-box;
  }

  html,
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: ${Typography.fontFamily};
    font-size: 14px;
    color: ${colors.withWeight(carbon, 700)};

    line-height: 1.5em;
    text-transform: none;
    letter-spacing: normal;
    font-weight: 400;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    line-height: 1em;
  }
`
