import React from 'react'
import styled from '@emotion/styled'
import { Message } from '@waylay/react-components'
import { get, has } from 'lodash-es'
import { IErrorResponse } from '~/lib/types'

const ErrorMessage = ({ error }: { error: IErrorResponse }) => {
  const errors = has(error, 'response.data.details')
    ? (get(error, 'response.data.details', []) as any).map(error =>
        get(error, 'msgs.0'),
      )
    : [get(error, 'response.data.error', 'Something went wrong')]

  return (
    <Message kind="danger">
      <StyledErrors>
        {errors.map((error, index) => (
          <li key={`error-${index}`}>{error}</li>
        ))}
      </StyledErrors>
    </Message>
  )
}

const StyledErrors = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 1em;
`

export default ErrorMessage
