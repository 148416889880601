import { MetaItem } from './Overview'
import { Icon } from '@waylay/react-components'
import { MouseEvent } from 'react'

interface ITag {
  label: string
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
}

const Tag = ({ label, onClick = () => {} }: ITag) => (
  <MetaItem {...{ label, onClick }}>
    <Icon name="local_offer" />
    {label}
  </MetaItem>
)

export default Tag
