import { isEmpty, get } from 'lodash-es'
import client from '../../../lib/client'
import useSearchQuery from '../../../hooks/useSearch'
import { RESOURCES_LIST_KEY } from '~/lib/QueryKeys'
import { EType, ResourceManagement } from '../Resources.types'
import { useNavigate } from 'react-router'
import { IResponse } from '~/lib/types'
import { useMutation, useQuery } from '@tanstack/react-query'

interface IUseResourcesProps {
  limit: number
  skip: number
}

const fetchResources = async (filter: object) => {
  const data = await client.resources.search(filter, {
    headers: { Accept: 'application/hal+json' },
  })
  return {
    items: get(data, '_embedded.values', []) as ResourceManagement[],
    totalResults: get(data, 'total', 0) as number,
    numberOfResults: get(data, '_embedded.values', []).length,
  }
}

const fetchResourceTypesFn = async ({
  limit,
  skip,
  text,
  filters,
}: {
  limit: number
  skip: number
  text?: string
  filters?: object
}) => {
  const params = {
    limit,
    skip,
    filter: text,
    ...filters,
  }
  const data = await client.types.list(params)

  return {
    items: get(data, 'values', []) as ResourceManagement[],
    totalResults: get(data, 'total', 0) as number,
    numberOfResults: get(data, 'values', []).length,
  }
}

const createFn = async (arg: { values: any; type: string }) => {
  const { values, type } = arg
  return client[type].create(values)
}

const useResources = (type: EType, options: IUseResourcesProps) => {
  const { textQuery, search, parsedQuery } = useSearchQuery()
  const { limit, skip } = options ?? {}

  const filter =
    type === EType.resource
      ? isEmpty(search) || !isEmpty(textQuery)
        ? { filter: search }
        : { query: search }
      : {
          filters: parsedQuery,
          text: textQuery,
        }

  const state = useQuery({
    queryKey: [RESOURCES_LIST_KEY, JSON.stringify(filter), limit, skip],
    queryFn: async () => {
      if (type === EType.resource) {
        return await fetchResources({ limit, skip, ...filter })
      } else {
        return await fetchResourceTypesFn({ limit, skip, ...filter })
      }
    },
  })

  return {
    state,
  }
}

export const useCreate = (type: EType) => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: createFn,
    onSuccess: (response: IResponse) => {
      navigate(`/${type}/${encodeURIComponent(response.entity.id)}`)
    },
  })
}

export default useResources
