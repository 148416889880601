import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/browser'
import { patchAccessibilityStyles } from '@waylay/react-components'
import pjson from '../package.json'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import App from './components/App/App'

// Import all assets
import './lib/asset-import'

const {
  REACT_APP_VERSION,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT = 'local',
} = window.env

// This is the React root element
const root = createRoot(document.getElementById('root'))

// This styles container will be used by the Emotion CacheProvider
const styles = document.getElementById('emotion')

// Patch browser a11y outline styles
patchAccessibilityStyles()

// initialize the Sentry client
Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  release: pjson.version,
  autoSessionTracking: false,
})

Sentry.setTag('git.version', REACT_APP_VERSION)

root.render(<App stylesContainer={styles} />)
