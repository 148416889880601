import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import chalk from 'chalk'
import { padEnd, takeRight, pad } from 'lodash-es'

import { LogLevel } from '~/lib/types'

const chalker = new chalk.Instance({ level: 3 })

// snazzy theme (sindresorhus)
const theme = {
  background: '#272935',
  green: '#5af78d',
  yellow: '#f3f99c',
  cyan: '#9aecfe',
  blue: '#57c7fe',
  red: '#ff5b56',
  magenta: '#ff69c0',
}

const createTerminal = (ref, isSimulator = false, isDebug = false) => {
  const term = new Terminal({
    disableStdin: true,
    cursorBlink: false,
    cursorWidth: 1,
    cursorStyle: 'underline',
    allowTransparency: false,
    scrollback: 10000,
    fontSize: 12,
    theme,
    convertEol: true,
  })
  term.open(isSimulator ? ref : ref.current)

  term.element.style.paddingLeft = '1em'
  term.element.style.paddingRight = '1em'
  if (isSimulator) {
    term.element.style.height = '100%'
    term.element.style.width = '100%'
  }
  if (isDebug) {
    term.element.style.paddingTop = '1em'
    term.element.style.maxHeight = '100px'
    term.element.style.width = '100%'
  }

  const fitAddon = new FitAddon()
  term.loadAddon(fitAddon)

  try {
    fitAddon.fit()
  } catch (_err) {}

  return {
    term,
    fitAddon,
  }
}

function levelColor(logLevel: LogLevel) {
  const COLORS = {
    [LogLevel.Debug]: chalker.cyan,
    [LogLevel.Info]: chalker.blue,
    [LogLevel.Warning]: chalker.yellow,
    [LogLevel.Error]: chalker.red,
  }

  return COLORS[logLevel] || chalker.white
}

interface ILogLine {
  time: string
  level: LogLevel
  message: string
  id: string | number
}

const createLogLine = (line: ILogLine) => {
  const { time, level = LogLevel.Info, message, id } = line

  const timeLabel = chalker.green(time)
  const levelString = padEnd(level, 5)
  const levelLabel = levelColor(level)(levelString)

  // use the last 12 characters of the taskId
  const taskLabel = chalker.magenta(pad(takeRight(String(id), 12).join(''), 12))

  // let's fix newlines to also include carriage return
  const fmtMessage = message.replace(/\n/g, '\n\r')
  return chalk`${timeLabel} ${taskLabel} ${levelLabel} ${fmtMessage}`
}

export { theme, createTerminal, createLogLine }
