import { ILoadingConfig } from '../Loading'

/**
 * Generates a loading configuration object for the templates list loader.
 * @returns {ILoadingConfig} The loading configuration object.
 */
export const generateTemplatesListLoaderConfig = (): ILoadingConfig => ({
  bars: [
    {
      nbBars: 2,
    },
  ],
  loaderHeight: 25,
  nbRepeats: 25,
})

/**
 * Generates the configuration for the loading component used in the templates details page.
 * @param width - The width of the loading component.
 * @returns An array of loading configurations.
 */
export const generateTemplatesDetailsLoaderConfig = (
  width: number,
): ILoadingConfig[] => [
  {
    bars: [
      {
        nbBars: 2,
        width: width * 0.5,
      },
    ],
    buttons: [
      {
        horizontalStartingPoint: width - 440,
        width: 145,
        height: 35,
      },
      {
        horizontalStartingPoint: width - 285,
        width: 120,
        height: 35,
      },
      {
        horizontalStartingPoint: width - 155,
        width: 140,
        height: 35,
      },
    ],
    loaderHeight: 40,
    isWhiteBackground: true,
  },
  {
    rectangles: [{ width }],
  },
]
