import Waylay from '@waylay/client'
import Resource from '@waylay/client/dist/resource'

export class UsageClient {
  usage: Resource

  constructor(waylayClient: Waylay) {
    this.usage = waylayClient.billing
  }

  async metrics(series?: string[], from?: any): Promise<any> {
    return await this.usage._request({
      path: '/historical',
      params: {
        series: [].concat(series).join(','),
        ...(from && { from }),
      },
    })
  }
}
