import React, { useEffect, useState } from 'react'
import { Select } from '@waylay/react-components'
import client from '~/lib/client'
import { isArray } from 'lodash-es'
import { ISelectOption } from '~/lib/types'

interface IVersionsSelectProps {
  value: string
  setFieldValue: Function
  pluginValue?: string
}

const VersionsSelect = ({
  value,
  setFieldValue,
  pluginValue,
}: IVersionsSelectProps) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    const fetchVersions = async () => {
      if (pluginValue !== '') {
        try {
          const versions = await client.registry.plugs.listVersions(pluginValue)
          const versionsNames = versions.entities.map(entity => {
            return {
              label: entity.plug.version,
              value: entity.plug.version,
            }
          })
          setOptions(versionsNames)
        } catch (err) {
          setOptions([])
        }
      } else {
        setOptions([])
      }
    }

    fetchVersions()
  }, [pluginValue])

  return (
    <Select
      aria-label="version-select"
      options={isArray(options) ? options : []}
      noOptionsMessage="No versions"
      onChange={(v: ISelectOption) => {
        setFieldValue(v.value)
      }}
      value={{ label: value, value }}
      isSearchable={false}
    />
  )
}

export default VersionsSelect
