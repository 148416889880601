import React, { useMemo } from 'react'
import { css } from '@emotion/core'
import {
  Button,
  Message,
  Modal,
  Segment,
  Form,
  Wizard,
  Table,
  Toggle,
  Select,
} from '@waylay/react-components'
import { useModal } from 'react-modal-hook'
import { useFormik } from 'formik'
import { get, reject, last, mapValues } from 'lodash-es'

import useTemplate from './useTemplate'
import useAvailablePlugs from './availablePlugs'
import { semverNewer, bestSemverMatch, IAvailablePlugUpgrade } from './util'
import { IfFulfilled, IfPending, IfRejected } from '../Common/QueryHelpers'

interface IMigrationProps {
  availableUpgrades: IAvailablePlugUpgrade[]
  templateId: string
  taskCount: number
  onSuccess?: () => void
}

export const useMigrationWizard = ({
  availableUpgrades,
  templateId,
  taskCount,
  onSuccess,
}: IMigrationProps) => {
  const { migrate } = useTemplate()

  const migrateTemplate = migrate({
    onResolve: () => {
      formik.setStatus(null)
      hideModal()
      onSuccess()
    },
    onReject: (err: Error) => {
      formik.setStatus(err)
    },
  })

  const formik = useFormik({
    initialValues: {
      plugsToUpgrade: availableUpgrades,
    },
    onSubmit: ({ plugsToUpgrade }) => {
      const enabledPlugsToUpgrade = filterEnabledUpgrades(plugsToUpgrade)
      migrateTemplate.mutate({
        templateId,
        plugsToBeUpdated: enabledPlugsToUpgrade,
      })
    },
  })

  const filterEnabledUpgrades = (plugsToUpgrade: IAvailablePlugUpgrade[]) => {
    return plugsToUpgrade.filter(plug => plug.enabled)
  }

  const addPlugToUpgrade = (upgrade: IAvailablePlugUpgrade) => {
    const newPlugs = reject(
      formik.values.plugsToUpgrade,
      plug => plug.name === upgrade.name,
    ).concat(upgrade)

    formik.setFieldValue('plugsToUpgrade', newPlugs)
  }

  const removePlugToUpgrade = (name: string) => {
    const newPlugs = reject(
      formik.values.plugsToUpgrade,
      upgrade => upgrade.name === name,
    )
    formik.setFieldValue('plugsToUpgrade', newPlugs)
  }

  const findUpgradeByName = (name: string) => {
    return formik.values.plugsToUpgrade.find(p => p.name === name)
  }

  const enableUpgrade = (name: string) => {
    const upgrade = findUpgradeByName(name)
    removePlugToUpgrade(name)
    addPlugToUpgrade({ ...upgrade, enabled: true })
  }

  const disableUpgrade = (name: string) => {
    const upgrade = findUpgradeByName(name)
    removePlugToUpgrade(name)
    addPlugToUpgrade({ ...upgrade, enabled: false })
  }

  const hasPlugToUpgrade = (name: string) => {
    return formik.values.plugsToUpgrade.some(
      upgrade => upgrade.name === name && upgrade.enabled,
    )
  }

  // 1. select which plugs you want to upgrade
  const stepOne = availableVersions => (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Plug</Table.HeaderCell>
          <Table.HeaderCell>Current Version</Table.HeaderCell>
          <Table.HeaderCell>Newer Version</Table.HeaderCell>
          <Table.HeaderCell style={{ width: 10 }} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {availableUpgrades.map(upgrade => (
          <Table.Row key={upgrade.name}>
            <Table.Cell>{upgrade.name}</Table.Cell>
            <Table.Cell>{upgrade.version}</Table.Cell>
            <Table.Cell>
              <IfPending state={availableVersions}>
                <Select
                  isSearchable={false}
                  placeholder="Loading..."
                  isLoading
                  isDisabled
                />
              </IfPending>
              <IfFulfilled state={availableVersions}>
                {data => (
                  <Select
                    isSearchable={false}
                    options={newerVersionOptions(
                      upgrade.version,
                      data[upgrade.name],
                    )}
                    defaultValue={defaultVersionSelection(
                      upgrade.version,
                      data[upgrade.name],
                    )}
                    onChange={({ value }) => {
                      addPlugToUpgrade({ ...upgrade, newerVersion: value })
                    }}
                  />
                )}
              </IfFulfilled>
              <IfRejected state={availableVersions}>
                {() => (
                  <Message kind="danger" basic>
                    Failed to load versions
                  </Message>
                )}
              </IfRejected>
            </Table.Cell>
            <Table.Cell>
              <Toggle
                checked={hasPlugToUpgrade(upgrade.name)}
                onChange={event => {
                  event.target.checked
                    ? enableUpgrade(upgrade.name)
                    : disableUpgrade(upgrade.name)
                }}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )

  const stepTwo = useMemo(
    () => (
      <div>
        <p>
          You are about to migrate <strong>{taskCount}</strong> tasks.{' '}
          <strong>They will lose their current state and restart.</strong>
        </p>
        <p>Are you sure you wish to continue?</p>
        {formik.status instanceof Error && (
          <Message kind="danger">
            {get(
              formik.status,
              'response.data.error',
              formik.status.toString(),
            )}
          </Message>
        )}
      </div>
    ),
    [taskCount, formik.status],
  )

  const hasSelectedAtLeastOnePlug =
    filterEnabledUpgrades(formik.values.plugsToUpgrade).length > 0
  const onAfterClose = () => formik.resetForm()

  const [showModal, hideModal] = useModal(() => {
    const newerVersions = useAvailablePlugs(availableUpgrades, {
      onResolve: plugs => {
        return mapValues(plugs, (versions, name) => {
          const upgrade = findUpgradeByName(name)
          const current = upgrade.version
          const bestMatch = bestSemverMatch(current, versions)
          addPlugToUpgrade({ ...upgrade, newerVersion: bestMatch })
        })
      },
    })

    return (
      <Modal isOpen onRequestClose={hideModal} onAfterClose={onAfterClose}>
        <Wizard steps={[stepOne(newerVersions), stepTwo]}>
          {({ currentStep, isLast, isFirst, next, previous }) => (
            <Form onSubmit={formik.handleSubmit}>
              <Segment.Group
                css={css`
                  width: 600px;
                `}
              >
                <Segment.Header>Migrate Template</Segment.Header>
                <Segment>{currentStep}</Segment>
                <Segment.Footer>
                  <Button
                    disabled={isFirst}
                    kind="secondary"
                    onClick={() => previous()}
                  >
                    Previous
                  </Button>
                  {!isLast && (
                    <Button
                      disabled={!hasSelectedAtLeastOnePlug}
                      kind="primary"
                      onClick={() => next()}
                      style={{ marginLeft: '5px' }}
                    >
                      Next
                    </Button>
                  )}
                  {isLast && (
                    <Button
                      kind="primary"
                      onClick={() => formik.handleSubmit()}
                      style={{ marginLeft: '5px' }}
                      disabled={migrateTemplate.isPending}
                      loading={migrateTemplate.isPending}
                    >
                      Migrate {taskCount} tasks
                    </Button>
                  )}
                </Segment.Footer>
              </Segment.Group>
            </Form>
          )}
        </Wizard>
      </Modal>
    )
  }, [
    JSON.stringify(formik.values),
    stepOne,
    stepTwo,
    migrateTemplate.isPending,
  ])

  return [showModal]
}

const defaultVersionSelection = (current: string, versions: string[]) => {
  const bestMatch = bestSemverMatch(current, versions)
  const latest = last(versions)

  if (!bestMatch) {
    return versionToOption(latest)
  }

  return versionToOption(bestMatch)
}

const versionToOption = (version: string) => ({
  label: 'v' + version,
  value: version,
})

const newerVersionOptions = (current: string, versions: string[]) => {
  return semverNewer(current, versions)?.map(versionToOption)
}
