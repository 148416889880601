import React, { useRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { decode, encode } from '@waylay/bn-parser'
import GraphEditor from '@waylay/graph-editor'
import { DateTime } from 'luxon'
import { reduce, sortBy } from 'lodash-es'

import useDebug from './useDebug'
import PlugContainer from '../PluginsAndWebscripts/Plugins/usePlugs'
import NodeInformation from './NodeConfiguration'
import NodeHistory, { ITimelineEvent, TimelineEventType } from './NodeHistory'

// load the editor styles
import styles from './Debugger.styles'
import { ITask } from './types'
import { IfFulfilled, IfPending } from '../Common/QueryHelpers'
import Loading from '../Common/Loading/Loading'

interface State {
  state: string
  probability: number
}

export interface IStateChange {
  timestamp: number
  nodeName: string
  oldState: State
  newState: State
}

const Debugger = ({ task }) => {
  const { network, nodes = [], resource, variables } = task
  const graphCanvas = useRef(null)
  const graphInstance = useRef(null)
  const plugs = PlugContainer.useContainer()
  const { client, disconnect } = useDebug({ taskId: task.ID })
  const [focusedNodes, setFocusedNodes] = useState([])
  const focusedNode = focusedNodes.length === 1 ? focusedNodes[0] : null

  useEffect(() => {
    if (!client) return

    client.addEventListener('stateChange', onStateChange)

    return () => {
      disconnect()
    }
  }, [client])

  useEffect(() => {
    // we have to wait until the DOM elem is available and the plug cache is populated
    if (!graphCanvas.current || !plugs.data) return

    const graph = new GraphEditor({
      elements: [],
      container: graphCanvas.current,
      editable: false,
      selectable: true,
      controls: false,
      theme: 'light',
      parser: {
        encode,
        decode,
      },
    })

    graph.import(network, { plugCache: plugs.data })
    graph.fitToContainer()

    graph.on('nodeFocus', () =>
      setFocusedNodes(graph.getSelectedNodes().map(n => n.data)),
    )

    graph.on('nodeBlur', () =>
      setFocusedNodes(graph.getSelectedNodes().map(n => n.data)),
    )

    nodes.forEach(node => {
      const { mostLikelyState, name } = node
      // when a task is stopped the mostLikelyState is reset
      if (!node.mostLikelyState) return

      const { probability, state } = mostLikelyState
      if (probability > 0.99) graph.focusSingleState(name, state)
    })

    graphInstance.current = graph
  }, [graphCanvas.current, plugs.data])

  function onStateChange(msg: { data: string }) {
    if (!graphInstance.current) return // graph instance not ready yet

    const payload: IStateChange = JSON.parse(msg.data)
    const { nodeName, newState, oldState } = payload

    graphInstance.current.unfocusState(nodeName, oldState.state)
    if (newState.probability > 0.99)
      graphInstance.current.focusSingleState(nodeName, newState.state)
  }

  const getTimeline = () => {
    if (!focusedNode) return []
    return fetchInvocationTimelineForNode(task, focusedNode.label)
  }

  return (
    <div style={{ position: 'relative' }}>
      <IfPending state={plugs}>
        <Loading
          bars={[
            {
              height: '67vh',
              isRandomWidth: false,
            },
          ]}
        />
      </IfPending>
      <IfFulfilled state={plugs}>
        <GraphContainer ref={graphCanvas}></GraphContainer>
      </IfFulfilled>

      {focusedNode && (
        <NodeInfoWrapper>
          <NodeInformation
            node={focusedNode}
            resource={resource}
            variables={variables}
          />
          <NodeHistory
            css={css`
              margin-top: 1rem;
            `}
            timeline={getTimeline()}
          />
        </NodeInfoWrapper>
      )}
    </div>
  )
}

function fetchInvocationTimelineForNode(
  task: ITask,
  nodeName: string,
): ITimelineEvent[] {
  const { history = {}, actuatorsLastInvocations = [] } = task

  const sensorEvents: ITimelineEvent[] = reduce(
    history,
    (acc: ITimelineEvent[], events, key) => {
      return acc.concat(
        events.map(event => {
          const { time, ...rest } = event

          return {
            timestamp: DateTime.fromMillis(time),
            nodeLabel: key,
            type: TimelineEventType.ProbabilityChange,
            ...rest,
          }
        }),
      )
    },
    [],
  )

  const actuatorEvents: ITimelineEvent[] = actuatorsLastInvocations.map(
    invocation => {
      const { time, action, ...rest } = invocation

      return {
        timestamp: DateTime.fromMillis(time),
        nodeLabel: action,
        type: TimelineEventType.ActuatorInvocation,
        ...rest,
      }
    },
    [],
  )

  const sortedEvents = sortBy(
    [].concat(sensorEvents, actuatorEvents),
    event => event.timestamp,
  ).reverse()

  return sortedEvents.filter(event => event.nodeLabel === nodeName)
}

export const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 69vh;
  position: relative;
  background: white;

  ${styles};
`

export const NodeInfoWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  max-width: 320px;
`

export default Debugger
