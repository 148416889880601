export enum EType {
  resource = 'resources',
  resourceType = 'resourceTypes',
}

export enum EClientType {
  resources = 'resources',
  resourceTypes = 'types',
}

export enum ESingularType {
  resources = 'resource',
  resourceTypes = 'resource type',
}

export enum EPluralType {
  resources = 'Resources',
  resourceTypes = 'Resource Types',
}

export enum EConstraintStatus {
  Ineffect = 'ineffect',
  Applying = 'applying',
  Failed = 'failed',
}

export type ResourceManagement = IResource | IResourceType

export interface IResource {
  id: string
  name: string
  tags: string[]
  metrics: IMetric[]
  [key: string]: any
}

interface IMetric {
  name: string
  valueType: string
  metricType: string
  unit: string
}

export interface IResourceType {
  id: string
  name: string
  templates: ITemplate[]
  $constraints: IConstraint[]
  tags: string[]
  [key: string]: any
}

export interface ITemplate {
  gatesNeedFullObservation: boolean
  templateName: string
  resetObservations: boolean
  frequency: number
  type: string
  parallel: boolean
}

export interface IConstraint {
  status: string
  constraintId: string
}

export interface IResourceManagementState {
  items: ResourceManagement[]
  totalResults: number
  numberOfResults: number
}

export interface IAttributes {
  description: string
  id: string
  name: string
}

export interface IResourceDataProps {
  details: IResource
  detailsNormalized: IResource
  constraintsList: IConstraint[]
  constraintsDetails: any
  resourceType: IResourceType
  inherited: string[]
  overwritten: string[]
}

export interface IResourceTypeDataProps {
  details: IResourceType
  constraintsList: IConstraint[]
  constraintsDetails: any
}
