import { createContainer } from 'unstated-next'
import client from '../../../lib/client'
import { useLocation } from 'react-router'
import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

interface IRuntime {
  name: string
  version: string
  archiveFormat?: string
  title: string
}

interface IRequestVersion {
  deprecated: false
  upgradable: false
  version: string
  title: string
  description: string
}

interface IRequestRuntime {
  name: string
  title: string
  description: string
  archiveFormat: string
  versions: IRequestVersion[]
}

export const RUNTIME_KEY = 'RUNTIMES'

export const useRuntimes = () => {
  const location = useLocation()

  const { data, isLoading, refetch } = useQuery({
    queryKey: [RUNTIME_KEY],
    queryFn: () => fetchRuntimes(),
  })

  useEffect(() => {
    if (
      location.pathname.includes('plugins') ||
      location.pathname.includes('webscripts')
    ) {
      refetch()
    }
  }, [location.pathname])

  return {
    allRuntimesIsFulfilled: !isLoading && data?.allRuntimes,
    loading: isLoading,
    majorRuntimes: data?.majorRuntimes,
    allRuntimes: data?.allRuntimes,
    callbackRuntimesMap: data?.callbackRuntimesMap,
    nonCallbackRuntimesMap: data?.nonCallbackRuntimesMap,
  }
}

const fetchRuntimes = async (): Promise<{
  majorRuntimes: IRuntime[]
  allRuntimes: IRuntime[]
  callbackRuntimesMap: IRuntime[]
  nonCallbackRuntimesMap: IRuntime[]
}> => {
  const functionType = window.location.pathname.includes('plugins')
    ? 'plugs'
    : 'webscripts'
  const major = await client.registry.runtimes.list({
    functionType,
    latest: true,
    includeDeprecated: false,
  })
  const all = await client.registry.runtimes.list({
    functionType,
    latest: false,
    includeDeprecated: false,
  })

  const majorRuntimes = []

  major.runtimes.forEach((runtime: IRequestRuntime) => {
    const newRuntime = {
      name: runtime.name,
      version: runtime.versions[0].version,
      archiveFormat: runtime.archiveFormat,
      title: runtime.title,
    }
    majorRuntimes.push(newRuntime)
  })

  const allRuntimes = []
  all.runtimes.forEach((runtime: IRequestRuntime) => {
    runtime.versions.forEach(minorRuntime => {
      const newRuntime = {
        name: runtime.name,
        version: minorRuntime.version,
        archiveFormat: runtime.archiveFormat,
        title: minorRuntime.title.replace(' Webscript', ''),
      }
      allRuntimes.push(newRuntime)
    })
  })

  const callbackRuntimes = await client.registry.runtimes.list({
    functionType,
    archiveFormat: 'native-',
    tags: 'callback',
  })
  const callbackRuntimesMap = []

  callbackRuntimes.runtimes.forEach((runtime: IRequestRuntime) => {
    const newRuntime = {
      name: runtime.name,
      version: runtime.versions[0].version,
      archiveFormat: runtime.archiveFormat,
      title: runtime.title,
    }
    callbackRuntimesMap.push(newRuntime)
  })

  const runtimesWithoutCallback = await client.registry.runtimes.list({
    functionType: 'plugs',
    archiveFormat: 'native-',
    tags: ['callback-', 'preview-'],
  })
  const nonCallbackRuntimesMap = []

  runtimesWithoutCallback.runtimes.forEach((runtime: IRequestRuntime) => {
    const newRuntime = {
      name: runtime.name,
      version: runtime.versions[0].version,
      archiveFormat: runtime.archiveFormat,
      title: runtime.title,
    }
    nonCallbackRuntimesMap.push(newRuntime)
  })

  return {
    majorRuntimes,
    allRuntimes,
    callbackRuntimesMap,
    nonCallbackRuntimesMap,
  }
}

export const RuntimeContainer = createContainer(useRuntimes)
