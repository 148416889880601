import styled from '@emotion/styled'
import { colors, carbon, watermelon } from '@waylay/react-components'

const GridFields = styled.div`
  display: grid;
  align-items: center;
`

export const ColumnFields = styled(GridFields)`
  grid-template-columns: max-content auto;
  grid-gap: 0.5rem;
  margin-bottom: 0.5rem;

  > *:nth-child(even) {
    justify-self: end;
  }
`

export const NoProperties = styled.div`
  color: ${colors.withWeight(carbon, 300)};
  margin: 0.5rem 1rem;
  font-size: 0.9rem;
`

export const PropertyField = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const InputRow = styled.div`
  display: flex;
  align-items: center;
`

export const SmallErrorMessage = styled.span`
  font-size: 0.9em;
  color: ${watermelon};
`
