import React from 'react'
import { sortBy } from 'lodash-es'

import Tag from '~/components/Common/Tag'

const Tags = ({ tags = [], onClick }) => {
  // Tags sorted by key
  const sortedTags = sortBy(Object.entries(tags), tag => tag[0].toLowerCase())

  return (
    <>
      {sortedTags.map(([key, value]) => (
        <Tag
          label={`${key}: ${value}`}
          key={key}
          onClick={e => onClick(e, `${key}:${value}`)}
        />
      ))}
    </>
  )
}

export default Tags
