import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { get } from 'lodash-es'
import client from '~/lib/client'
import { ITemplate } from '~/lib/types'

const TEMPLATES_KEY = 'templates'

interface ITemplateDetails {
  template: ITemplate
  taskCount: number
  resourceTypes: any[]
}

const fetchTemplate = async ({ id }): Promise<ITemplateDetails> => {
  const { WAYLAY_RESOURCES_URL, WAYLAY_API_GATEWAY_URL } = window.env

  const template = await (client.templates.get(id, {
    format: 'bn',
  }) as unknown as Promise<ITemplate>)

  const taskCount = await client.tasks.count({ template: template.name })

  const resourceTypes =
    WAYLAY_API_GATEWAY_URL || WAYLAY_RESOURCES_URL
      ? await client.types
          .list({ template: String(template.name) })
          .then(data => get(data, 'values', []))
      : []

  return { template, taskCount, resourceTypes }
}

const usePromoteToDiscoveryTemplate = (
  key: string | string[],
  details: ITemplateDetails,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      await client.templates.setDiscoveryTemplate(details.template.name)
      return details
    },
    onSuccess: () => {
      const newTemplate = {
        ...details,
        template: { ...details.template, discoveryTemplate: true },
      }
      queryClient.setQueryData([key], newTemplate)
    },
  })
}

// remove the discovery template (there can be only one)
const useDemoteDiscoveryTemplate = (
  key: string | string[],
  details: ITemplateDetails,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      // empty params will remove the discovery template
      await client.templates.setDiscoveryTemplate()

      return details
    },
    onSuccess: () => {
      const newTemplate = {
        ...details,
        template: { ...details.template, discoveryTemplate: false },
      }
      queryClient.setQueryData([key], newTemplate)
    },
  })
}

export const fetchRunningTasks = ({ template }: { template: string }) => {
  return client.tasks.list({ template })
}

const useTemplateFetch = (id: string) => {
  const key = [TEMPLATES_KEY, id]

  const { isLoading, data, error, refetch, isSuccess, isError } = useQuery({
    queryKey: [key],
    queryFn: async () => await fetchTemplate({ id }),
    gcTime: 0,
  })

  const reload = async () => await refetch()
  const promoteToDiscoveryTemplate = usePromoteToDiscoveryTemplate(key, data)
  const demoteDiscoveryTemplate = useDemoteDiscoveryTemplate(key, data)

  return {
    isLoading,
    isSuccess,
    isError,
    error,
    data,
    reload,
    promoteToDiscoveryTemplate,
    demoteDiscoveryTemplate,
  }
}

export default useTemplateFetch
