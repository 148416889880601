import { useQuery } from '@tanstack/react-query'
import client from '../lib/client'

export const fetchResourcesByIds = function (ids: String[]): Promise<Object> {
  let queryIds = `id:in(`
  const idsArray = ids.map(id => `"${id}"`)

  queryIds = queryIds + idsArray.join(',') + ')'
  return client.resources.search(
    { query: queryIds },
    {
      headers: { Accept: 'application/hal+json' },
    },
  )
}

export const fetchResources = function (filter: object): Promise<Object> {
  return client.resources.search(filter, {
    headers: { Accept: 'application/hal+json' },
  })
}

function useResourcesList({ filter = {}, limit, skip }) {
  const state = useQuery({
    queryKey: ['resources-list-key', filter, skip, limit],
    queryFn: async () => await fetchResources({ limit, skip, ...filter }),
  })

  return state
}

export default useResourcesList
