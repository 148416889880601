import React from 'react'
import { Settings } from 'luxon'
import { enableMapSet } from 'immer'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Context from './Context'
import Dashboard from '../Dashboard/Dashboard'
import 'semantic-ui-css/semantic.min.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// enable ES2015 Map and Set
// see https://immerjs.github.io/immer/installation/
enableMapSet()

const App = ({ stylesContainer }) => {
  // set the default locale to en-US for consistent locale strings
  Settings.defaultLocale = 'en-US'

  const queryClient = new QueryClient()

  return (
    // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Context stylesContainer={stylesContainer}>
        <DndProvider backend={HTML5Backend}>
          <Dashboard />
        </DndProvider>
      </Context>
    </QueryClientProvider>
    // </React.StrictMode>
  )
}

export default App
