import React from 'react'
import { Button } from '@waylay/react-components'
import { isEmpty } from 'lodash-es'

interface IDescriptorButtonProps {
  value: any
  setDescriptor?: Function
  size?: string
  name?: string
}

const DescriptorButton = ({
  value,
  size = 'medium',
  setDescriptor,
}: IDescriptorButtonProps) => {
  return (
    <Button
      data-testId="show-descriptor-button"
      style={{ marginRight: 0, width: '100px' }}
      size={size}
      onClick={() => setDescriptor()}
      disabled={isEmpty(value)}
    >
      Descriptor
    </Button>
  )
}

export default DescriptorButton
