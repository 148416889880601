import React, { useMemo } from 'react'
import { Select, watermelon } from '@waylay/react-components'
import { useVault } from '~/components/Settings/Organisation/Settings/useVault'

const VaultSelect = ({ onChange, value, placeholder, isError }) => {
  const { vault } = useVault()
  const { data, isLoading } = vault

  const options = useMemo(() => {
    return data?.map(setting => ({
      label: setting.key,
      value: setting.key,
    }))
  }, [data])

  const selectProps = {
    onChange,
    options,
    placeholder,
    isLoading,
    isClearable: true,
    defaultValue: value ? { label: value, value } : undefined,
  }

  return (
    <Select
      {...selectProps}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          borderColor: isError ? watermelon : '#e6e6e6',
          '&:hover': {
            borderColor: isError ? watermelon : '#b3b3b3',
          },
        }),
      }}
    />
  )
}

export default VaultSelect
