import React from 'react'
import styled from '@emotion/styled'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash-es'
import AttributesForm from './AttributesForm'
import useAttributes from './useAttributes'

const Attributes = () => {
  const { values } = useFormikContext<{ resourceTypeId: string }>()
  const { resourceTypeId } = values

  const { filteredAttributes, resourceType } = useAttributes(resourceTypeId)

  if (isEmpty(filteredAttributes)) return null

  return (
    <>
      <FormLabel style={{ marginTop: '1.5em' }}>
        Additional attributes
      </FormLabel>
      <AttributesForm
        attributes={filteredAttributes}
        resourceType={resourceType}
      />
    </>
  )
}

const FormLabel = styled.label`
  margin-bottom: 0.5em;
`

export default Attributes
