import React, {
  forwardRef,
  useCallback,
  useRef,
  HTMLProps,
  useState,
  useEffect,
} from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { ErrorMessage, Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  Form,
  Icon,
  Popup,
  Segment,
  List,
  carbon,
  concrete,
  watermelon,
} from '@waylay/react-components'
import { DateTime, Duration } from 'luxon'
import DatePicker from 'react-datepicker'

import { SmallLabel } from '~/components/Explore/Form'
import { DT_FORMAT } from '~/components/Common/Date/TimestampParser'

interface IWindowOption {
  label: string
  value: string
}

interface IQuickSelection {
  label: string
  from: string
  until: string
}

const relativeWindows: IWindowOption[] = [
  { label: 'Last 10 minutes', value: 'PT10M' },
  { label: 'Last 30 minutes', value: 'PT30M' },
  { label: 'Last 1 hour', value: 'PT1H' },
  { label: 'Last 8 hours', value: 'PT8H' },
  { label: 'Last 24 hours', value: 'PT24H' },
  { label: 'Last 7 days', value: 'P7D' },
  { label: 'Last 14 days', value: 'P14D' },
  { label: 'Last 30 days', value: 'P30D' },
]

interface ITimeWindowProps {
  onChange: Function
  from?: string
  until?: string
  window?: string
  disabled?: boolean
  isExplorer?: boolean
}

function formatDate(value: string) {
  if (value) {
    if (value === 'now') {
      return value
    }

    if (DateTime.fromISO(value).isValid) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)
    }

    if (DateTime.fromFormat(value, DT_FORMAT).isValid) {
      return DateTime.fromFormat(value, DT_FORMAT).toLocaleString(
        DateTime.DATETIME_MED,
      )
    }

    if (Duration.fromISO(value).isValid) {
      const now = DateTime.local()
      const duration = Duration.fromISO(value)

      // the duration is actually negative (ie. -P1D) so we have to add a negative duration
      return now.plus(duration).toLocaleString(DateTime.DATETIME_MED)
    }
  }

  return 'Invalid Date'
}

const validDate = Yup.string().test(
  'valid-date',
  'Date is not a valid ISO date, "now" or an ISO Duration',
  (value: string) => {
    const isNow = value === 'now'
    const isISODate =
      DateTime.fromFormat(value, DT_FORMAT).isValid ||
      DateTime.fromISO(value).isValid
    const isISODuration = Duration.fromISO(value).isValid

    return isNow || isISODate || isISODuration
  },
)

const TimeWindowPicker = ({
  onChange,
  from,
  until,
  window,
  disabled = false,
  isExplorer = false,
}: ITimeWindowProps) => {
  const popup = useRef(null)
  const [fromDate, setFromDate] = useState(fromTimepickerDate(from))
  const [untilDate, setUntilDate] = useState(fromTimepickerDate(until))

  // quick selections have to be defined inside the component because they are time sensitive
  const commonQuickSelections: IQuickSelection[] = [
    {
      label: 'Yesterday',
      from: DateTime.local()
        .minus({ days: 1 })
        .startOf('day')
        .toFormat(DT_FORMAT),
      until: DateTime.local().startOf('day').toFormat(DT_FORMAT),
    },
    {
      label: 'Today so far',
      from: DateTime.local().startOf('day').toFormat(DT_FORMAT),
      until: 'now',
    },
    {
      label: 'This week so far',
      from: DateTime.local().startOf('week').toFormat(DT_FORMAT),
      until: 'now',
    },
    {
      label: 'Last week',
      from: DateTime.local()
        .minus({ weeks: 1 })
        .startOf('week')
        .toFormat(DT_FORMAT),
      until: DateTime.local().startOf('week').toFormat(DT_FORMAT),
    },
  ]

  const explorerQuickSelections: IQuickSelection[] = [
    {
      label: 'This month so far',
      from: DateTime.local().startOf('month').toFormat(DT_FORMAT),
      until: 'now',
    },
    {
      label: 'This year so far',
      from: DateTime.local().startOf('year').toFormat(DT_FORMAT),
      until: 'now',
    },
  ]

  const quickSelections = isExplorer
    ? commonQuickSelections.concat(explorerQuickSelections)
    : commonQuickSelections

  const setWindow = useCallback(window => {
    onChange({ window, from: `-${window}`, until: null })

    if (popup.current) {
      popup.current.hide()
    }
  }, [])

  const setAbsolute = useCallback(({ from, until }) => {
    onChange({ from, until, window: null })

    if (popup.current) {
      popup.current.hide()
    }
  }, [])

  const humanFrom = formatDate(from)
  const humanUntil = formatDate(until)

  const niceDates = `${humanFrom} – ${humanUntil}`

  const windowFromOptions: IWindowOption = relativeWindows.find(
    o => o.value === window,
  )
  const isWindowActive = useCallback(
    value => windowFromOptions?.value === value,
    [windowFromOptions],
  )

  const textValue = windowFromOptions?.label ?? niceDates

  const validationSchema = Yup.object().shape({
    from: validDate,
    until: validDate,
  })

  const intialValues = {
    from: from ?? '',
    until: until ?? 'now',
  }

  const CalendarButton = forwardRef<
    HTMLButtonElement,
    HTMLProps<HTMLButtonElement>
  >(function CalendarButton({ onClick }, ref) {
    return (
      <Button onClick={onClick} ref={ref}>
        <Icon name="event" />
      </Button>
    )
  })

  function toTimepickerDate(date: Date): string {
    return DateTime.fromISO(date.toISOString()).toFormat(DT_FORMAT)
  }

  function fromTimepickerDate(date: string): Date | string {
    if (DateTime.fromISO(date).isValid) {
      return DateTime.fromISO(date).toJSDate()
    }

    if (date && DateTime.fromFormat(date, DT_FORMAT).isValid) {
      return DateTime.fromFormat(date, DT_FORMAT).toJSDate()
    }

    if (Duration.fromISO(date).isValid) {
      const duration = Duration.fromISO(date)
      return DateTime.now().plus(duration).toJSDate()
    }

    return new Date()
  }

  useEffect(() => {
    setFromDate(fromTimepickerDate(from))
  }, [from])

  useEffect(() => {
    setUntilDate(fromTimepickerDate(until))
  }, [until])

  return (
    <Popup
      onCreate={instance => {
        popup.current = instance
      }}
      maxWidth="auto"
      placement="bottom-end"
      content={
        <Segment padding={0}>
          <TwoColumns>
            <Column>
              <ListHeader>Custom time range</ListHeader>
              <AbsoluteTime>
                <Formik
                  onSubmit={setAbsolute}
                  validationSchema={validationSchema}
                  initialValues={intialValues}
                  enableReinitialize
                >
                  {({ handleChange, handleSubmit, values, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <GridFields>
                        <div>
                          <SmallLabel htmlFor="from">From</SmallLabel>
                          <div style={{ display: 'flex' }}>
                            <Form.Input.Group fluid>
                              <Form.Input
                                id="from"
                                name="from"
                                onChange={handleChange}
                                value={values.from}
                              />
                            </Form.Input.Group>
                            <div style={{ marginLeft: '8px' }}>
                              <DatePicker
                                showTimeSelect
                                timeIntervals={15}
                                selected={fromDate}
                                onChange={date => {
                                  setFromDate(date)
                                  const parsedDate = toTimepickerDate(date)
                                  setFieldValue('from', parsedDate)
                                }}
                                customInput={<CalendarButton />}
                              />
                            </div>
                          </div>
                          <ErrorMessage name="from">
                            {message => (
                              <SmallErrorMessage>{message}</SmallErrorMessage>
                            )}
                          </ErrorMessage>
                        </div>
                        <div>
                          <SmallLabel htmlFor="until">Until</SmallLabel>
                          <div style={{ display: 'flex' }}>
                            <Form.Input.Group fluid>
                              <Form.Input
                                id="until"
                                name="until"
                                onChange={handleChange}
                                value={values.until}
                              />
                            </Form.Input.Group>
                            <div style={{ marginLeft: '8px' }}>
                              <DatePicker
                                showTimeSelect
                                timeIntervals={15}
                                selected={untilDate}
                                onChange={date => {
                                  setUntilDate(date)
                                  const parsedDate = toTimepickerDate(date)
                                  setFieldValue('until', parsedDate)
                                }}
                                customInput={<CalendarButton />}
                              />
                            </div>
                          </div>
                          <ErrorMessage name="until">
                            {message => (
                              <SmallErrorMessage>{message}</SmallErrorMessage>
                            )}
                          </ErrorMessage>
                        </div>
                      </GridFields>
                      <Button type="submit" kind="primary">
                        Apply
                      </Button>
                    </Form>
                  )}
                </Formik>
              </AbsoluteTime>
            </Column>
            <Column
              css={css`
                border-left: solid 1px ${concrete};
              `}
            >
              <ListHeader>Relative date ranges</ListHeader>
              <TimeList interactive>
                {relativeWindows.map(({ value, label }) => (
                  <List.Item
                    key={value}
                    active={isWindowActive(value)}
                    onClick={() => setWindow(value)}
                  >
                    {label}
                  </List.Item>
                ))}
              </TimeList>
            </Column>
            <Column
              css={css`
                border-left: solid 1px ${concrete};
              `}
            >
              <ListHeader>Quick selections</ListHeader>
              <TimeList interactive>
                {quickSelections.map(({ from, until, label }) => (
                  <List.Item
                    key={label}
                    onClick={() => setAbsolute({ from, until })}
                  >
                    {label}
                  </List.Item>
                ))}
              </TimeList>
            </Column>
          </TwoColumns>
        </Segment>
      }
    >
      <Button kind="secondary" outline disabled={disabled}>
        <Icon name="date_range" />
        &nbsp;{textValue}&nbsp;
        <Icon name="keyboard_arrow_down" />
      </Button>
    </Popup>
  )
}

const GridFields = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 0.5rem;
  margin-bottom: 0.5rem;
`

const Column = styled.div`
  overflow-y: auto;
`

const ListHeader = styled.header`
  font-size: 0.75rem;
  color: ${carbon};
  padding-top: 0.75rem;
  padding-right: 1rem;
  padding-bottom: 0.1rem;
  padding-left: 1rem;
`

const TwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 70vh;
`

const AbsoluteTime = styled.div`
  width: 240px;
  padding: 0.5rem 1rem;
`

const TimeList = styled(List)`
  line-height: 1.25rem;
`

const SmallErrorMessage = styled.span`
  font-size: 0.9em;
  color: ${watermelon};
`

export default TimeWindowPicker
