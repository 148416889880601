import React, { useRef } from 'react'
import { Formik, Field } from 'formik'
import { Button, Form, Icon, Message } from '@waylay/react-components'
import styled from '@emotion/styled'
import * as yup from 'yup'

const { Input } = Form

const ErrorContainer = styled('div')`
  position: absolute;
  width: 100%;
  padding: 0.2em 0.5em;
`

const ErrorMessage = styled(Message)`
  width: 100%;
  background: white;
  padding: 0.2em;
`

const EditNameForm = ({ name, onSubmit }) => (
  <Formik
    initialValues={{ name, isInitialized: false }}
    validationSchema={yup.object().shape({
      name: yup.string(),
    })}
    onSubmit={onSubmit}
  >
    {formik => {
      const ref = useRef<HTMLInputElement>()

      return (
        <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <Field
              name="name"
              as={Input}
              innerRef={ref}
              autoFocus
              data-testid="input-resource-name"
            />
            <Button
              type="submit"
              size="tiny"
              css={{ marginLeft: '5px' }}
              data-testid="save-resource-name"
            >
              <Icon name="check" /> Save
            </Button>
          </div>
          {formik.errors.name ? (
            <ErrorContainer>
              <ErrorMessage kind="danger" outline>
                {formik.errors.name}
              </ErrorMessage>
            </ErrorContainer>
          ) : (
            ''
          )}
        </Form>
      )
    }}
  </Formik>
)

export default EditNameForm
