import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { FieldArray, useFormikContext } from 'formik'
import {
  Button,
  Icon,
  Form,
  Toggle,
  Select,
  concrete,
} from '@waylay/react-components'
import { get, map } from 'lodash-es'
import ResourceSelect from '~/components/Common/ResourceSelect'
import {
  ConstraintTypes,
  IArrayAttribute,
  IEnumAttribute,
  IObjectAttribute,
} from '../../../../ResourceConstraints/Constraints.types.'
import AttributesForm from './AttributesForm'
import { getTypeInput } from './getTypeInput'

interface IName {
  name: string
}
interface INamePlaceholderProps extends IName {
  placeholder: string
}

export const StringInput = ({ name, placeholder }: INamePlaceholderProps) => {
  const { values, handleChange } = useFormikContext()
  const value = get(values, name)

  return (
    <FormGroup>
      <Form.Input
        name={name}
        type="text"
        placeholder={placeholder}
        fluid
        value={value}
        onChange={handleChange}
      />
    </FormGroup>
  )
}

export const NumberInput = ({ name, placeholder }: INamePlaceholderProps) => {
  const { values, handleChange } = useFormikContext()
  const value = get(values, name)

  return (
    <FormGroup>
      <Form.Input
        name={name}
        type="number"
        placeholder={placeholder}
        fluid
        value={value}
        onChange={handleChange}
      />
    </FormGroup>
  )
}

export const BooleanInput = ({ name }: IName) => {
  const { values, setFieldValue } = useFormikContext()
  const value = get(values, name)

  return (
    <Toggle
      checked={value}
      onChange={e => setFieldValue(name, e.target.checked)}
    />
  )
}

export const EnumInput = ({
  name,
  attribute,
}: {
  name: string
  attribute: IEnumAttribute
}) => {
  const { values, setFieldValue } = useFormikContext()

  const items = attribute?.type?.items ?? []
  const options = items.map(item => ({ label: item, value: item }))

  const formikValue = get(values, name)
  const valueOption = options.find(option => option.value === formikValue)

  return (
    <Select
      value={valueOption}
      options={options}
      onChange={({ value }) => setFieldValue(name, value)}
    />
  )
}

export const ResourceRefInput = ({ name }: IName) => {
  const { values, setFieldValue } = useFormikContext()
  const value = get(values, name)

  return (
    <ResourceSelect
      name={name}
      resource={value}
      onChange={({ value }) =>
        setFieldValue(name, { $ref: `/resources/${encodeURIComponent(value)}` })
      }
    />
  )
}

export const ObjectInput = ({
  name,
  attribute,
}: {
  name: string
  attribute: IObjectAttribute
}) => {
  const formik = useFormikContext()

  // extra default because of object arrays
  const attributes = (
    get(attribute, 'type.attributes') ||
    get(attribute, 'type.elementType.attributes') ||
    []
  ).map(attribute => ({ ...attribute, name: `${name}.${attribute.name}` }))

  /*
   * Set default to empty object to handle the situation where the object
   * property is required but non of its own (sub) attributes are required
   * resulting in the property being undefined rather than a emtpy object
   */
  useEffect(() => {
    formik.setFieldValue(name, {})
  }, [])

  return (
    <ObjectInputContainer>
      <AttributesForm attributes={attributes} />
    </ObjectInputContainer>
  )
}

export const ArrayInput = ({
  name,
  attribute,
}: {
  name: string
  attribute: IArrayAttribute
}) => {
  const { values } = useFormikContext()

  // Note: Exception for boolean is required as the array will
  // otherwise be a line of toggles
  const elementType = get(attribute, 'type.elementType.type')
  const Input =
    elementType === ConstraintTypes.boolean
      ? BooleanArrayInput
      : getTypeInput(elementType)

  const items = values[name]

  const pushItem = elementType === ConstraintTypes.object ? {} : ''

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <div>
          {map(items, (_, index: number) => {
            const itemName = `${name}.${index}`
            const props =
              elementType === ConstraintTypes.object
                ? { name: itemName, attribute }
                : { name: itemName }

            return (
              <ArrayItem key={itemName}>
                <Input {...props} />
                <RemoveItem
                  kind="secondary"
                  onClick={() => arrayHelpers.remove(index)}
                  style={{ marginRight: 0 }}
                >
                  <Icon name="remove" />
                </RemoveItem>
              </ArrayItem>
            )
          })}
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              size="small"
              onClick={() => arrayHelpers.push(pushItem)}
              style={{ marginRight: 0 }}
            >
              <Icon name="add" /> Add {name} item
            </Button>
          </div>
        </div>
      )}
    />
  )
}

export const BooleanArrayInput = ({ name }: IName) => {
  const { values, setFieldValue } = useFormikContext()

  const formikValue = get(values, name)
  const valueOption = BOOLEAN_ARRAY_ITEMS.find(
    option => option.value === formikValue,
  )

  return (
    <div style={{ width: '100%' }}>
      <Select
        value={valueOption}
        options={BOOLEAN_ARRAY_ITEMS}
        onChange={({ value }) => setFieldValue(name, value)}
      />
    </div>
  )
}

const FormGroup = styled(Form.Input.Group)`
  display: inline-flex;
  align-items: center;
  width: 100%;
`

const ArrayItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5em;
`

const RemoveItem = styled(Button)`
  margin-left: 0.5em;
`

const BOOLEAN_ARRAY_ITEMS = [
  { label: 'true', value: true },
  { label: 'false', value: false },
]

const ObjectInputContainer = styled.div`
  width: 100%;
  margin-left: 0.4em;
  border-left: 2px solid ${concrete};
  border-radius: 1px;
  padding-left: 1.2em;
`
