import React from 'react'
import ResourceSelect from '~/components/Common/ResourceSelect'
import { IPropertyInput } from '../../types'

const ResourceField = ({
  property,
  value,
  setFieldValue,
  isError,
}: IPropertyInput) => {
  const { name, defaultValue } = property

  const handleChange = ({ value }) => {
    setFieldValue(name, value)
  }

  return (
    <ResourceSelect
      resource={value}
      allowAnyInput
      resetValueOnBlur={false}
      placeholder={defaultValue}
      isError={isError}
      onChange={handleChange}
    />
  )
}

export default ResourceField
