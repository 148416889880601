export interface AlarmsSort {
  by: string
  order: EAlarmSortOrder
}

export enum EAlarmSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IAlarm {
  id: string
  creationTime: string
  lastUpdateTime: string
  lastTriggeredTime: string
  type: string
  text: string
  timestamp: string
  severity: EAlarmSeverity
  status: EAlarmStatus
  count: number
  history: AlarmHistoryItem[]

  source: {
    id: string
  }
  self: string
}

export enum EAlarmSeverity {
  Warning = 'WARNING',
  Minor = 'MINOR',
  Major = 'MAJOR',
  Critical = 'CRITICAL',
}

export enum EAlarmStatus {
  Active = 'ACTIVE',
  Acknowledged = 'ACKNOWLEDGED',
  Cleared = 'CLEARED',
}

export interface AlarmHistoryItem {
  id: string
  text: string
  timestamp: string
  type: string
  changes: Change[]
}

export interface Change {
  attribute: string
  type: string
  oldValue: EAlarmStatus | string
  newValue: EAlarmStatus | string
}
