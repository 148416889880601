/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createContainer } from 'unstated-next'
import client from '~/lib/client'
import { PlugV0 } from '~/lib/types'
import {
  PlugTypeSingular,
  PlugV2,
  PlugsV2,
  PlugsV2Entity,
} from '../Common/Types'
import { cloneDeep, get } from 'lodash-es'
import { removeEmptyProps, normalizeForPartialMatch } from '~/lib/util'
import { useQuery } from '@tanstack/react-query'

export type PlugV0WithType = Omit<PlugV0, 'type'> & { type: PlugTypeSingular }
export type PlugsV2EntityWithType = Omit<PlugsV2Entity, 'plug'> & {
  plug: Omit<PlugV2, 'type'> & { type: PlugTypeSingular }
}
export type IPlugsV2WithType = Omit<PlugsV2, 'entities'> & {
  entities: PlugsV2EntityWithType
}

const fetchSensors = (): Promise<PlugV0WithType[]> =>
  client.sensors
    .list()
    .then((list = []) =>
      (list as PlugV0[]).map(
        plug => ({ ...plug, type: 'sensor' } as PlugV0WithType),
      ),
    )

const fetchActuators = (): Promise<PlugV0WithType[]> =>
  client.actuators
    .list()
    .then((list = []) =>
      (list as PlugV0[]).map(
        plug => ({ ...plug, type: 'actuator' } as PlugV0WithType),
      ),
    )

const fetchAllPlugs = async () =>
  await Promise.all([fetchSensors(), fetchActuators()]).then(
    ([sensors, actuators]) => [...sensors, ...actuators],
  )

function usePlugs() {
  return useQuery({
    queryKey: ['plugs-key', client.token],
    queryFn: fetchAllPlugs,
    gcTime: 0,
  })
}

const fetchAllPlugsV2 = async () => {
  const { entities } = await client.registry.plugs.list(
    removeEmptyProps({
      includeDeprecated: false,
      includeDraft: false,
      limit: 4000,
      page: 0,
    }),
  )
  const formattedPlugs = entities.map(entity => {
    const configuration = get(entity, 'plug.interface.input', [])?.map(
      input => {
        const inputNew = cloneDeep(input)
        inputNew.type = inputNew.dataType
        delete inputNew.dataType
        return inputNew
      },
    )

    const output = get(entity, 'plug.interface.output', [])?.map(output => {
      const outputNew = cloneDeep(output)
      outputNew.parameter = outputNew.name
      delete outputNew.name
      return outputNew
    })

    return {
      author: get(entity, 'plug.metadata.author'),
      configuration,
      description: get(entity, 'plug.metadata.description'),
      iconURL: get(entity, 'plug.metadata.iconURL'),
      isDeprecated: get(entity, 'deprecated'),
      metadata: {
        author: get(entity, 'plug.metadata.author'),
        description: get(entity, 'plug.metadata.description'),
        iconURL: get(entity, 'plug.metadata.iconURL'),
        tags: get(entity, 'plug.metadata.tags'),
        documentation: {
          supportedStates: get(entity, 'plug.metadata.documentation.states'),
          configuration: get(entity, 'plug.metadata.documentation.input'),
          rawData: get(entity, 'plug.metadata.documentation.output'),
        },
      },
      name: get(entity, 'plug.name'),
      rawData: output,
      states: get(entity, 'plug.interface.states'),
      status: get(entity, 'status'),
      type: get(entity, 'plug.type'),
      version: get(entity, 'plug.version'),
    }
  })

  return formattedPlugs
}

export const usePlugsV2 = () => {
  return useQuery({
    queryKey: ['plugs-v2-key', client.token],
    queryFn: fetchAllPlugsV2,
    gcTime: 0,
  })
}

export const fetchPlugsV2 = async (
  filter: { name?: string; tags?: string } = {},
  reqLimit?: number,
  page?: number,
): Promise<IPlugsV2WithType> => {
  filter.name = normalizeForPartialMatch(filter.name)
  const { limit, count, entities } = await client.registry.plugs.list(
    removeEmptyProps({
      includeDeprecated: false,
      limit: reqLimit || 4000,
      page,
      ...filter,
    }),
  )
  return { limit, count, page, entities }
}

export default createContainer(usePlugs)
