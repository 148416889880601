import React from 'react'
import { useQueryClient } from '@tanstack/react-query'

interface IPendingProps {
  state: IState
  children?: React.ReactNode
  shouldRevalidate?: boolean
}

interface IRejectedProps {
  state: IState
  children?: React.ReactNode | ((error: Error) => React.ReactNode)
  shouldRevalidate?: boolean
}

interface IFulfilledProps {
  state: IState
  children?: React.ReactNode | ((data: any) => React.ReactNode)
  shouldRevalidate?: boolean
}

export interface IState {
  [key: string]: any
}

const isLoading = (state: IState) => {
  return state.isLoading || state.isPending
}

const IfPending = ({ state, children }: IPendingProps) => {
  return isLoading(state) ? <>{children}</> : null
}

const IfRejected = ({ state, children }: IRejectedProps) => {
  const isRejected = state.isError && !isLoading(state)
  if (!isRejected) return null
  if (typeof children === 'function') return children(state.error)
  return children
}

const IfFulfilled = ({ state, children }: IFulfilledProps) => {
  const isFulfilled = state?.data && state.isSuccess
  if (!isFulfilled) return null
  if (typeof children === 'function') return children(state.data)
  return children
}

const IFMutationInitial = ({ state, children }: IFulfilledProps) => {
  const isInitial = !state.error && !state.data && state.isIdle
  if (!isInitial) return null
  if (typeof children === 'function') return children(state.data)
  return children
}

//TBD FLAVIa
const useMutateQueryPartialKey = () => {
  const queryClient = useQueryClient()

  const mutateQueryPartialKey = (partialKey: string | string[]) => {
    const queryCache = queryClient.getQueryCache()
    const queries = queryCache.getAll()

    queries.forEach(query => {
      const queryKey = query.queryKey
      if (Array.isArray(partialKey)) {
        const matches = partialKey.every(
          (key, index) => queryKey[index] === key,
        )
        if (matches) {
          queryClient.invalidateQueries({ queryKey: queryKey })
        }
      } else {
        if (queryKey.includes(partialKey)) {
          queryClient.invalidateQueries({ queryKey: queryKey })
        }
      }
    })
  }

  return mutateQueryPartialKey
}

export {
  IfPending,
  IfRejected,
  IfFulfilled,
  useMutateQueryPartialKey,
  IFMutationInitial,
}
