import React from 'react'
import {
  StreamlineIcon,
  Tooltip,
  watermelon,
  lemon,
} from '@waylay/react-components'
import { Alerts } from '@streamlinehq/streamline-bold/lib/interface-essential'
import styled from '@emotion/styled'
import { getEnv } from '~/lib/flags'

const criticalThreshold = parseFloat(getEnv('CRITICAL_THRESHOLD', '0.20'))
const warningThreshold = parseFloat(getEnv('WARNING_THRESHOLD', '0.10'))

const HealthStatus = ({ health }) => {
  const errorsRate = health?.errorsRate
  let color = criticalThreshold ? watermelon : lemon
  let threshold = 0

  if (errorsRate >= criticalThreshold && errorsRate > warningThreshold) {
    color = watermelon
    threshold = criticalThreshold
  } else if (errorsRate >= warningThreshold && errorsRate < criticalThreshold) {
    color = lemon
    threshold = warningThreshold
  } else {
    return null
  }
  return (
    <Tooltip
      placement="right"
      content={`Task error rate is above ${threshold * 100}%`}
    >
      <Centered>
        <StreamlineIcon
          width={12}
          height={12}
          iconData={Alerts.AlertCircle}
          color={color}
        />
      </Centered>
    </Tooltip>
  )
}

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
`

export default HealthStatus
