import { sortBy, uniqBy } from 'lodash-es'
import { AsyncSelect } from '@waylay/react-components'
import client from '~/lib/client'
import { ISelectOption } from '~/lib/types'

interface IPluginSelect {
  value: string
  setFieldValue: Function
}

const noOptionsMessage = ({ inputValue }: { inputValue: string }) =>
  inputValue.length
    ? `No plugins found containing '${inputValue}'`
    : 'Start typing…'

const PluginSelect = (props: IPluginSelect) => {
  const { value, setFieldValue } = props

  const onChange = (v: ISelectOption): void => {
    setFieldValue({ plugin: v.value, version: null })
  }

  const loadOptions = async (inputValue: string) => {
    try {
      const plugins = await client.registry.plugs.list({
        includeDraft: true,
        name: `*${inputValue}*`,
      })
      const pluginsNames = plugins.entities.map(entity => {
        return {
          label: entity.plug.name,
          value: entity.plug.name,
        }
      })
      return sortBy(uniqBy(pluginsNames, 'label'), 'label')
    } catch (err) {
      return []
    }
  }

  return (
    <AsyncSelect
      aria-label="plugin-select"
      isClearable
      noOptionsMessage={noOptionsMessage}
      onChange={v => onChange(v || {})}
      defaultOptions
      cacheOptions
      loadOptions={loadOptions}
      value={{ label: value, value }}
    />
  )
}

export default PluginSelect
