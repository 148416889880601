import { useState, useMemo } from 'react'
import searchString from 'search-string'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  getQuery,
  parseFilterQuery,
  parseSort,
  setSearchParam,
} from '../lib/util'
import { isEqual, omit } from 'lodash-es'
import { createContainer } from 'unstated-next'

const useSearchQuery = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const initialState = searchString.parse('')
  const [query, setQuery] = useState(initialState)
  const [sort, setSort] = useState(initialState)

  useMemo(() => {
    setQuery(parseFilterQuery(location.search))
    setSort(parseSort(location.search))
  }, [location.search])

  const updateQuery = (value = '') => {
    const search = setSearchParam(location.search, 'query', value)
    navigate({ search })

    setQuery(searchString.parse(value))
  }

  const updateSort = ({ by, order }) => {
    if (by && order) {
      const value = `${by}:${order}`
      const parsedValue = searchString.parse(value)
      if (!isEqual(sort.conditionArray, parsedValue.conditionArray)) {
        const search = setSearchParam(location.search, 'sort', value)
        navigate({ search })

        setSort(searchString.parse(value))
      }
    }
  }

  const parsedQuery = useMemo(
    () => omit(query.getParsedQuery(), 'exclude'),
    [query],
  )

  const parsedSort = useMemo(
    () => omit(sort.getParsedQuery(), 'exclude'),
    [sort],
  )

  const sortObject = useMemo(() => {
    if (parsedSort) {
      const [sortBy] = Object.keys(parsedSort)

      return {
        by: sortBy ?? '',
        order: parsedSort[sortBy]?.[0] ?? ['desc'],
      }
    }
  }, [parsedSort])

  const textQuery = useMemo(() => query.getAllText(), [query])

  return {
    search: getQuery(location.search),
    rawQuery: query,
    textQuery,
    parsedQuery,
    parsedSort,
    sortObject,
    setQuery: updateQuery,
    setSort: updateSort,
  }
}

export default useSearchQuery
export const SearchQueryContainer = createContainer(useSearchQuery)
