import styled from '@emotion/styled'
import { Segment } from '@waylay/react-components'

export const Head = styled(Segment)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const Name = styled.header`
  font-weight: 600;
`

export const Description = styled.span`
  font-size: 0.9rem;
`

export const Metadata = styled.div`
  margin-top: 0.25rem;
`

export const Actions = styled.div`
  display: flex;

  > a,
  > button {
    margin-right: 0.5em;
  }
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1em;
`
