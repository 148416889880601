import React, { useState } from 'react'
import { DateTime } from 'luxon'
import styled from '@emotion/styled'
import { Icon, carbon, CopyToClipboard, colors } from '@waylay/react-components'
import { HoverIconWrapper, RowFlexBox } from './DetailsGrid'

export const Timestamp = styled.span`
  font-size: 0.8rem;
  color: ${carbon};
  vertical-align: middle;
  display: inline-flex;
  white-space: pre;
  align-items: center;
`
const handleButtonClick = (event: React.MouseEvent) => {
  event.stopPropagation()
}

export const RelativeTimestamp = ({ timestamp, text = '' }) => {
  const [human, setHuman] = useState(false)

  if (!timestamp) return <TimestampComponent text={text} timestamp="never" />

  const datetime = DateTime.fromISO(timestamp)
  const humanTimestamp = datetime.toLocaleString(DateTime.DATETIME_SHORT)
  const relativeTimestamp = datetime.toRelative()

  const toggleFormat = () => setHuman(human => !human)

  return (
    <TimestampComponent
      timestamp={human ? humanTimestamp : relativeTimestamp}
      title={humanTimestamp}
      onClick={() => toggleFormat()}
      text={text}
    />
  )
}

export const AbsoluteTimestamp = ({
  text = '',
  timestamp,
  testid = '',
  icon = 'access_time',
  hasCopyIcon,
}: {
  text?: string
  testid?: string
  icon?: string
  timestamp: string
  hasCopyIcon?: boolean
}) => {
  const datetime = DateTime.fromISO(timestamp)
  const humanTimestamp = datetime.toLocaleString(DateTime.DATETIME_SHORT)

  return (
    <>
      {hasCopyIcon ? (
        <TimestampComponentWithCopyIcon
          text={text}
          timestamp={humanTimestamp}
          testid={testid}
          icon={icon}
        />
      ) : (
        <TimestampComponent
          text={text}
          timestamp={humanTimestamp}
          testid={testid}
          icon={icon}
        />
      )}
    </>
  )
}

interface ITimestampProps {
  timestamp: string
  title?: string
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => any
  text?: string
  testid?: string
  icon?: string
}

const TimestampComponent = ({
  timestamp,
  title,
  onClick,
  text,
  testid,
  icon,
}: ITimestampProps) => (
  <Timestamp title={title} onClick={onClick} data-testid={testid}>
    <Icon name={icon} /> {text} {timestamp}
  </Timestamp>
)

export const TimestampComponentWithCopyIcon = ({
  timestamp,
  title,
  onClick,
  text,
  testid,
  icon,
}: ITimestampProps) => (
  <HoverIconWrapper onClick={handleButtonClick}>
    <Timestamp title={title} onClick={onClick}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon name={icon} />
        <span data-testid={testid}>{`${text} ${timestamp}`}</span>
      </div>
      <RowFlexBox>
        <CopyToClipboard
          value={`${text} ${timestamp}`}
          trigger={ref => (
            <div style={{ cursor: 'pointer' }} ref={ref}>
              <Icon color={colors.withWeight(carbon, 200)} name="file_copy" />
            </div>
          )}
        />
      </RowFlexBox>
    </Timestamp>
  </HoverIconWrapper>
)
