import React, { useCallback, useState } from 'react'
import { Formik } from 'formik'
import { css } from '@emotion/core'
import { useModal } from 'react-modal-hook'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import {
  Button,
  Form,
  Icon,
  Input,
  Message,
  Modal,
  List,
  Segment,
  Popup,
} from '@waylay/react-components'
import explorer from './ExplorerContainer'
import client from '~/lib/client'
import { setSearchParam } from '~/lib/util'
import { Grouping, IChart, IQueryConfig } from './Editor/DataQueriesTypes'
import { ChartContainer } from './Editor/ChartContainer'
import { IfRejected } from '../Common/QueryHelpers'
import { useMutation } from '@tanstack/react-query'

// this one converts the IQueryConfig to the payload that the data queries support
// there's really no validation of any kind on the data queries API so changing this requires
// very thorough testing and validation (Gilles)
function transformQueryConfigToCorrectAPIPayload(queryConfig: IQueryConfig) {
  return {
    // if we send "none" as a string the API refuses to store the config for some reason
    freq:
      queryConfig.grouping === Grouping.None ? undefined : queryConfig.grouping,
    from: queryConfig.from,
    until: queryConfig.until,
    window: queryConfig.window,
    data: queryConfig.series,
  }
}

const transformMetaData = (charts: IChart[]) => {
  return {
    console: {
      charts: charts.map(chart => ({
        id: chart.id,
        name: chart.name,
        series: chart.series.map(serie => serie.seriesId),
      })),
    },
  }
}

const createOrReplaceQuery = async (arg: {
  isNew: boolean
  name: string
  query: any
  charts: any
}) => {
  const { isNew, name, query, charts } = arg
  const payload = transformQueryConfigToCorrectAPIPayload(query)
  const newCharts = transformMetaData(charts)

  // both API calls actually do the same PUT request
  if (isNew) {
    await client.queries.create(name, payload, newCharts)
  } else {
    await client.queries.update(name, payload, newCharts)
  }

  return { name, query }
}

const SaveButton = () => {
  const { queryName, queryConfig } = explorer.useContainer()
  const { charts } = ChartContainer.useContainer()
  const { search } = useLocation()
  const navigate = useNavigate()

  const [shouldCopyQuery, setShouldCopyQuery] = useState(false)
  const validQueryConfig = queryConfigSchema.isValidSync(queryConfig)

  const createOrUpdate = useMutation({
    mutationFn: createOrReplaceQuery,
    onSuccess: ({ name }) => {
      hideModal()
      const newSearch = setSearchParam(search, 'queryName', name)
      navigate({ search: newSearch })
    },
  })

  const handleSave = useCallback(
    ({ name }) => {
      const isNew = name !== queryName
      createOrUpdate.mutate({ isNew, name, query: queryConfig, charts })
      setShouldCopyQuery(false)
    },
    [queryName, JSON.stringify(queryConfig), charts],
  )

  const initialValues = {
    name: shouldCopyQuery ? `${queryName || ''}Copy` : queryName,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  })

  const [showModal, hideModal] = useModal(
    () => (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSave}
        validationSchema={validationSchema}
      >
        {({ errors, values, resetForm, handleSubmit, handleChange }) => (
          <Modal
            isOpen
            onRequestClose={hideModal}
            onRequestOpen={() => resetForm()}
          >
            <Form onSubmit={handleSubmit}>
              <Segment.Group
                css={css`
                  width: 600px;
                `}
              >
                <Segment.Header>
                  {shouldCopyQuery ? 'Save As ' : 'Save data query'}
                </Segment.Header>
                <Segment>
                  <label htmlFor="name">Name</label>
                  <Input.Group>
                    <Input
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                    />
                  </Input.Group>
                  {Object.keys(errors).length > 0 && (
                    <Message kind="danger" basic outline>
                      {Object.values(errors).map(err => err.toString())}
                    </Message>
                  )}
                  <IfRejected state={createOrUpdate}>
                    {(error: Error) => (
                      <Message kind="danger" outline>
                        Failed to save query: <code>{error.toString()}</code>
                      </Message>
                    )}
                  </IfRejected>
                </Segment>
                <Modal.Actions>
                  <Button outline kind="secondary" onClick={hideModal}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    kind="primary"
                    loading={createOrUpdate.isPending}
                    disabled={createOrUpdate.isPending}
                  >
                    Save
                  </Button>
                </Modal.Actions>
              </Segment.Group>
            </Form>
          </Modal>
        )}
      </Formik>
    ),
    [createOrUpdate, handleSave],
  )

  return (
    <>
      <Button.Group>
        <Button disabled={!validQueryConfig} onClick={() => showModal()}>
          <Icon name="save" /> {queryName ? 'Update' : 'Save'}
        </Button>
        {queryName && (
          <Popup
            placement="bottom-end"
            appendTo={() => document.body}
            content={
              <List as={Segment} padding={0} interactive>
                <List.Item
                  onClick={() => {
                    setShouldCopyQuery(true)
                    showModal()
                  }}
                >
                  <Icon name="save" /> Save As
                </List.Item>
              </List>
            }
          >
            <Button disabled={!validQueryConfig}>
              <Icon name="keyboard_arrow_down" />
            </Button>
          </Popup>
        )}
      </Button.Group>
    </>
  )
}

const queryConfigSchema = Yup.object().shape({
  series: Yup.array()
    .of(
      Yup.object().shape({
        resource: Yup.string().required(),
        metric: Yup.string().required(),
        aggregation: Yup.string().required(),
      }),
    )
    .required(),
})

export default SaveButton
