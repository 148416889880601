import React from 'react'
import { css } from '@emotion/core'
import {
  Button,
  Modal,
  Segment,
  List,
  Wizard,
  Icon,
} from '@waylay/react-components'
import { useModal } from 'react-modal-hook'
import Upload from './Upload'
import Configuration from './Configuration'
import { ImportType, OpenApiContainer } from './useUploadOpenApiPlug'
import _, { isEmpty } from 'lodash-es'
import './OpenApiWizard.css'
import { useNavigate } from 'react-router-dom'
import { generatePlugin } from '@waylay/oas-generator'
import client from '~/lib/client'
import { useFlag } from '~/lib/flags'
import { getScriptNames } from '../../Common/useCustomFunctions'
import { useProfile } from '../../../Dashboard/Sidebar/useProfile'

const stepDescription = {
  0: 'Upload OpenAPI Specifications',
  1: 'Generate Plugin',
}

const OpenApiModal = () => {
  const navigate = useNavigate()
  const profile = useProfile()
  const edgeMode = useFlag('edgeMode', false)
  const [showModal, hideModal] = useModal(() => {
    return (
      <OpenApiContainer.Provider>
        <Modal isOpen onRequestClose={hideModal}>
          <div
            css={css`
              min-width: 600px;
            `}
          >
            <Wizard
              steps={[
                <Upload key="upload" />,
                <Configuration key="configuration" />,
              ]}
            >
              {({
                currentIndex,
                currentStep,
                next,
                previous,
                isFirst,
                isLast,
              }: {
                currentIndex: number
                currentStep: number
                next: any
                previous: any
                isFirst: boolean
                isLast: boolean
              }) => {
                const {
                  file,
                  setValidationError,
                  url,
                  validationError,
                  plug,
                  importType,
                  validateApi,
                  api,
                  isPlugNameAllowed,
                  setGeneratePlugError,
                  isDirty,
                  setIsInCreation,
                  isInCreation,
                } = OpenApiContainer.useContainer()

                const convertGeneratedPlugToPlugV2 = async (
                  generatedPlug: any,
                ) => {
                  const runtimeName = 'node-legacy'
                  const runtimeVersion = '0.2.0'
                  const formData = new FormData()

                  const fullDependencies = {
                    name: generatedPlug.name,
                    version: '1.0.0',
                    dependencies: generatedPlug.dependencies,
                  }

                  const input = generatedPlug.metadata.configuration.map(
                    config => ({
                      mandatory: config.mandatory,
                      name: config.name,
                      dataType: config.type,
                      format: config.format,
                      defaultValue: config.defaultValue,
                    }),
                  )

                  const output = generatedPlug.metadata.rawData.map(
                    rawData => ({
                      mandatory: rawData.mandatory,
                      name: rawData.parameter,
                      dataType: rawData.dataType,
                      format: rawData.format,
                      defaultValue: rawData.defaultValue,
                    }),
                  )

                  const pluginData: any = {
                    name: generatedPlug.name,
                    version: '1.0.0',
                    type: generatedPlug.type,
                    runtime: runtimeName,
                    runtimeVersion,
                    interface: {
                      states: generatedPlug.metadata.supportedStates,
                      input,
                      output,
                    },
                    metadata: {
                      author: profile?.data?.fullName,
                      description: generatedPlug.metadata.description,
                      iconURL: generatedPlug.metadata.iconURL,
                      documentation: {
                        states: generatedPlug.metadata.documentation.states,
                        input: generatedPlug.metadata.documentation.input,
                        output: generatedPlug.metadata.documentation.output,
                      },
                    },
                  }

                  const dependenciesBlob = new Blob(
                    [JSON.stringify(fullDependencies, null, 2)],
                    {
                      type: 'application/json',
                    },
                  )

                  const dataBlob = new Blob(
                    [JSON.stringify(pluginData, null, 2)],
                    {
                      type: 'application/json',
                    },
                  )

                  const scriptBlob = new Blob([generatedPlug.script], {
                    type: 'application/txt',
                  })
                  const scriptNames = await getScriptNames(
                    runtimeName,
                    runtimeVersion,
                    true,
                  )

                  formData.append(
                    scriptNames.projectScriptName,
                    dependenciesBlob,
                  )
                  formData.append(scriptNames.manifestName, dataBlob)
                  formData.append(scriptNames.mainScriptName, scriptBlob)
                  return formData
                }

                const nextSteps = async (next: () => void) => {
                  if (isFirst) {
                    try {
                      if (isDirty || isEmpty(api)) {
                        await validateApi(
                          importType === ImportType.File ? file : url,
                        )
                      }
                      next()
                    } catch (err: any) {
                      setValidationError(err.message)
                    }
                  } else {
                    try {
                      setIsInCreation(true)
                      const codeGeneratedPlug = await generatePlugin(
                        api,
                        plug.path,
                        plug.operation,
                        plug.server,
                        plug.name,
                        plug.security,
                        plug.flow,
                      )
                      const codeGeneratedPlugJson =
                        JSON.parse(codeGeneratedPlug)
                      edgeMode
                        ? await client.sensors.create(codeGeneratedPlugJson)
                        : await convertGeneratedPlugToPlugV2(
                            codeGeneratedPlugJson,
                          ).then(async formData => {
                            await client.registry.plugs.create(formData, {
                              draft: true,
                            })
                          })
                      navigate(`/plugins/sensors/${plug.name}`)
                    } catch (err) {
                      setIsInCreation(false)
                      setGeneratePlugError(err.message)
                    }
                  }
                }

                const isDisabled =
                  (isFirst &&
                    ((!url && !file) ||
                      (importType === ImportType.File && validationError))) ||
                  (isLast &&
                    (_.isEmpty(plug) ||
                      _.isEmpty(plug?.path) ||
                      _.isEmpty(plug?.name) ||
                      _.isEmpty(plug?.operation) ||
                      !isPlugNameAllowed))

                return (
                  <Segment.Group style={{ padding: 0 }}>
                    <Segment.Header data-testid="current-step">
                      Step {currentIndex + 1}: {stepDescription[currentIndex]}
                    </Segment.Header>
                    <Segment
                      style={{
                        padding: 0,
                        width: currentStep === 1 ? '90%' : 'auto',
                      }}
                    >
                      {currentStep}
                    </Segment>
                    {!isInCreation && (
                      <Segment.Footer>
                        {!isFirst && (
                          <Button
                            data-testid="previous-button"
                            kind="primary"
                            onClick={() => previous()}
                          >
                            Previous
                          </Button>
                        )}
                        <Button
                          data-testid="next-button"
                          kind="primary"
                          disabled={isDisabled}
                          onClick={() => nextSteps(next)}
                          style={{ marginLeft: '5px' }}
                        >
                          {isLast ? 'Add plugin' : 'Validate'}
                        </Button>
                      </Segment.Footer>
                    )}
                  </Segment.Group>
                )
              }}
            </Wizard>
          </div>
        </Modal>
      </OpenApiContainer.Provider>
    )
  }, [profile])

  return (
    <List.Item onClick={showModal} data-testid="item-upload-openapi">
      <Icon name="folder" data-testid="button-upload-openapi" /> Upload OpenAPI
    </List.Item>
  )
}
export default OpenApiModal
