import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { Segment } from '@waylay/react-components'

import { get, isEmpty } from 'lodash-es'
import useWindowDimensions from '~/components/Simulator/useWindowDimensions'
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import { createTerminal } from '~/components/Common/LogTerminal'
import chalk from 'chalk'
import { WebscriptSeverity as Severity } from '~/lib/types'
import { getFormattedTimestamp } from '~/components/Logs/TimestampFormatting'
import Loading from '~/components/Common/Loading/Loading'
import { debugLoader } from './Debug'
import {
  IfFulfilled,
  IFMutationInitial,
  IfPending,
  IfRejected,
} from '~/components/Common/QueryHelpers'

function createLogLine(data) {
  const { level, message, timestamp } = data
  const severityStyled = (severitiesMap[level] || chalk.blue)(level.padEnd(7))
  return chalk(
    `${chalker.green(
      getFormattedTimestamp(timestamp),
    )} ${severityStyled} ${message}`,
  )
}

const LogLines = ({ log }) => {
  const { height, width } = useWindowDimensions()
  const terminalElementRef = useRef(null)
  const terminalRef = useRef<Terminal>(null)
  const fitAddonRef = useRef<FitAddon>(null)

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.dispose()
    }

    if (terminalElementRef.current) {
      const { term, fitAddon } = createTerminal(terminalElementRef, false, true)
      terminalRef.current = term
      fitAddonRef.current = fitAddon
    }
  }, [log])

  useEffect(() => {
    if (fitAddonRef.current) {
      try {
        fitAddonRef.current.fit()
      } catch (_err) {}
    }
  }, [width, height])

  useEffect(() => {
    if (terminalRef.current && log) {
      terminalRef.current.clear()
      log.forEach(entry => {
        terminalRef.current.writeln(createLogLine(entry))
      })
    }
  }, [log])

  return (
    <div>
      {isEmpty(log) ? (
        <span>No log output.</span>
      ) : (
        <div>
          <Logs ref={terminalElementRef} />
        </div>
      )}
    </div>
  )
}

const chalker = new chalk.Instance({ level: 3 })

const severitiesMap = {
  [Severity.Default]: chalker.white,
  [Severity.Debug]: chalker.magenta,
  [Severity.Notice]: chalker.blue,
  [Severity.Info]: chalker.blue,
  [Severity.Warning]: chalker.yellow,
  [Severity.Error]: chalker.red,
  [Severity.Critical]: chalker.rgb(141, 52, 45),
  [Severity.Alert]: chalker.red,
  [Severity.Emergency]: chalker.red,
}

const DebugLogs = ({ state }) => (
  <Segment>
    <IFMutationInitial state={state}>
      <>
        <LogLines log={[]} />
      </>
    </IFMutationInitial>
    <IfPending state={state}>
      <Loading {...debugLoader} />
    </IfPending>
    <IfFulfilled state={state}>
      {data => <LogLines log={data?.log} />}
    </IfFulfilled>
    <IfRejected state={state}>
      {error => <LogLines log={get(error, 'response.data.log')} />}
    </IfRejected>
  </Segment>
)

const Logs = styled.div`
  height: 100%;
`

export default DebugLogs
