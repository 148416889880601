import { Select, watermelon } from '@waylay/react-components'
import { IPropertyInput } from '../../types'

const EnumField = ({
  property,
  value,
  setFieldValue,
  isError,
  isClearable = true,
  isSearchable = true,
}: IPropertyInput) => {
  const { name, format, defaultValue } = property

  const handleChange = option => {
    setFieldValue(name, option?.value || null)
  }

  return (
    <Select
      cacheOptions
      name={name}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          borderColor: isError ? watermelon : '#e6e6e6',
          '&:hover': {
            borderColor: isError ? watermelon : '#b3b3b3',
          },
        }),
      }}
      aria-label={`enum-${name}`}
      key={`enum-select-${value}`}
      isClearable={isClearable}
      isSearchable={isSearchable}
      options={format?.values?.map(option => ({
        label: option,
        value: option,
      }))}
      value={value ? { label: value, value } : undefined}
      placeholder={defaultValue}
      onChange={handleChange}
    />
  )
}

export default EnumField
