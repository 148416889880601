import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import {
  colors,
  concrete,
  clearSky,
  Icon,
  Loader,
} from '@waylay/react-components'
import { isEmpty } from 'lodash-es'
import Box, { BoxType } from '../PluginsAndWebscripts/Plugins/OpenApiWizard/Box'

const UploadIndicator = styled.div<{ hover: boolean }>`
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: none;
  ${({ hover }) =>
    hover &&
    `
    display: flex;
    box-shadow: inset 0 0 3px ${clearSky};
  `}
`
const Target = styled.div`
  position: relative;
  height: 100%;
  em {
    padding: 0.5em 2em;
    display: block;
    justify-content: center;
    width: 100%;
    border: dashed 1px ${colors.withWeight(concrete, 500)};
    font-size: 0.8em;
  }
`

const Wrapper = styled.div<{ hover: boolean }>`
  ${({ hover }) =>
    hover &&
    `
    background-color: #e6f1ff;
    `}
  min-height: 75px;
  padding: 0.5em 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: dashed 1px ${colors.withWeight(concrete, 500)};
  font-size: 0.8em;
`

const FileImport = ({
  fileName,
  error,
  setError,
  onSuccess,
  formats = '',
  isOpenApi = false,
  shouldShowBoxes = true,
  shouldReturnMetadata = false,
  sizeLimit = '',
  fileFormat = null,
}: {
  fileName?: string
  error?: string
  setError?: any
  onSuccess: any
  formats?: string
  isOpenApi?: boolean
  shouldShowBoxes?: boolean
  shouldReturnMetadata?: boolean
  sizeLimit?: string
  fileFormat?: string
}) => {
  const [dragDepth, setDragDepth] = useState<number>(0)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const intervalId = useRef(null)

  useEffect(() => {
    setIsLoading(false)
    // clear timeout
    return () => {
      clearTimeout(intervalId.current)
    }
  }, [])

  const handleDragLeave = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
    setDragDepth(depth => depth - 1)
  }, [])

  const handleDragEnter = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
    setDragDepth(depth => depth + 1)
  }, [])

  const handleDragOver = useCallback(e => {
    if (!e.dataTransfer.types.includes('Files')) return
    e.preventDefault()
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'copy'
  }, [])

  const handleDrop = useCallback(e => {
    const { dataTransfer } = e
    setDragDepth(0)
    setIsLoading(true)
    formatSpec(dataTransfer.files[0])
    e.preventDefault()
    e.stopPropagation()
  }, [])

  useEffect(() => {
    if (isSuccess && isOpenApi) {
      setError(null)
    }
  }, [isSuccess])

  const formatSpec = (myFile: File) => {
    const reader = new FileReader()
    reader.readAsText(myFile)
    reader.onload = function () {
      const fileName: string = myFile?.name
      if (fileFormat && !fileName.includes(fileFormat)) {
        setError('Unsupported file format')
        setIsLoading(false)
      } else {
        if (shouldReturnMetadata) {
          onSuccess(fileName, myFile)
          setIsLoading(false)
        } else {
          const fileContent = reader.result as string
          successActions(fileContent, fileName)
        }
      }
    }
  }

  const successActions = (file: string, name: string) => {
    onSuccess(file, name)
    setIsSuccess(true)
    setIsLoading(false)
    const id = setTimeout(() => {
      setIsSuccess(false)
    }, 3000)
    intervalId.current = id
  }

  const handleUpload = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    const dummyInput: HTMLInputElement = document.createElement('input')
    dummyInput.type = 'file'
    dummyInput.multiple = false
    dummyInput.onchange = e => {
      setIsLoading(true)

      formatSpec((e.target as HTMLInputElement).files[0])
    }
    dummyInput.click()
  }

  return (
    <>
      <Target
        data-testid="drop-input"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <UploadIndicator hover={dragDepth > 0} />
        <Wrapper hover={dragDepth > 0}>
          {isLoading ? (
            <Loader size={32} />
          ) : (
            <>
              <Icon
                data-testid="file-upload-icon"
                name="file_upload"
                style={{ fontSize: '5em' }}
                color="blue"
              />

              <div
                data-testid="file-upload-text"
                style={{
                  width: '100%',
                  textAlign: 'center',
                  display: 'inline-block',
                  fontWeight: 'bold',
                }}
              >
                Drop your file here, or{' '}
                <div
                  data-testid="browse"
                  onClick={e => handleUpload(e)}
                  style={{
                    color: '#1c90ff',
                    display: 'inline-block',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  browse
                </div>
              </div>
            </>
          )}
          {formats && (
            <div
              data-testid="supported-formats"
              style={{
                width: '100%',
                fontSize: '0.8em',
                fontStyle: 'italic',
                textAlign: 'center',
              }}
            >
              Supported formats: {formats}
            </div>
          )}
          {sizeLimit && (
            <div
              data-testid="size-limit"
              style={{
                width: '100%',
                fontSize: '0.8em',
                textAlign: 'center',
              }}
            >
              Size limit: <b>{sizeLimit}</b>
            </div>
          )}
        </Wrapper>
      </Target>
      {shouldShowBoxes && isSuccess && (
        <Box
          type={BoxType.success}
          text="Your file has been successfully uploaded."
          title="Success"
        />
      )}
      {shouldShowBoxes && error && (
        <Box type={BoxType.error} text={error} title="Error" />
      )}
      {shouldShowBoxes && !isEmpty(fileName) && (
        <Box
          type={BoxType.information}
          text={`Current uploaded file: ${fileName}`}
        />
      )}
    </>
  )
}

export default FileImport
