import { Icon, List, Loader, carbon } from '@waylay/react-components'
import { useToasts } from 'react-toast-notifications'

import useFileSelector from '~/hooks/useFileSelector'
import usePlug, { PLUGIN_KEY } from './usePlug'
import { useFlag } from '~/lib/flags'
import { useMutateQueryPartialKey } from '~/components/Common/QueryHelpers'

function UploadPlug() {
  const { upload } = usePlug()
  const { addToast } = useToasts()

  const mutatePartialKey = useMutateQueryPartialKey()
  const edgeMode = useFlag('edgeMode', false)

  const handleFileUpload = (file: string, fileName: string) => {
    try {
      upload.mutate({ file, fileName })
      mutatePartialKey(PLUGIN_KEY)
    } catch (err) {
      addToast('Failed to upload plug: plug is not valid', {
        appearance: 'error',
      })
    }
  }

  const acceptedFile = edgeMode
    ? 'application/json,.json'
    : 'application/json,.json,application/gzip,.gz'

  const { open } = useFileSelector(handleFileUpload, {
    accept: acceptedFile,
  })

  return (
    <List.Item onClick={open}>
      {!upload.isPending ? (
        <Icon name="cloud_upload" style={{ marginRight: '4px' }} />
      ) : (
        <Loader size={14} color={carbon} style={{ marginRight: '4px' }} />
      )}
      Upload plug
    </List.Item>
  )
}

export default UploadPlug
