import React, { useState, lazy, Suspense, useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  useLocation,
} from 'react-router-dom'
import { colors, concrete } from '@waylay/react-components'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/browser'
import Topbar from './Topbar/Topbar'
import ExploreTopBar from '~/components/Explore/TopBar'
import Sidebar from './Sidebar/Sidebar'
import { LoginProvider, useLogin } from '../App/LoginContext'
import useConnectivity from './useConnectivity'
import ExplorerContainer from '../Explore/ExplorerContainer'
import { PaddingComponent } from '../Common/WithPadding'
import PlugsContainer from '../PluginsAndWebscripts/Plugins/usePlugs'
import EditorContainer from '../Tasks/Editor/EditorContext'
import TemplateContainer from '../Tasks/Editor/TemplateContext'
import Error from './Error'
import { Flag, useFlag } from '~/lib/flags'
import { retryImport } from '~/lib/retry'
import { ChartContainer } from '../Explore/Editor/ChartContainer'
import loadable from '@loadable/component'
import { EType } from '../Resources/Resources.types'
import { RuntimeContainer } from '../PluginsAndWebscripts/Common/useRuntimes'
import { ModalProvider } from 'react-modal-hook'
import UseQueryString from '~/hooks/useQueryString'
import { LicenseContainer } from '~/hooks/useLicense'
import Branding, { Head as BrandingHead } from '../App/Branding'
import { SimulatorContainer } from '../Simulator/useSimulator'
import RunningSimulators from '../App/RunningSimulator'
import SidebarContainer2 from './Sidebar/useCollapsed'

const LandingPage = lazy(() => import('../Landing/LandingPage'))
const Alarms = lazy(() => import('../Alarms/AlarmsRoutes'))
const Integrations = lazy(() =>
  retryImport(() => import('../Integrations/IntegrationsRouter')),
)
const Settings = lazy(() => import('../Settings/SettingsRouter'))
const Resources = lazy(() => import('../Resources/ResourcesRouter'))

const ResourceConstraints = lazy(
  () => import('../ResourceConstraints/ResourceConstraintsRoutes'),
)
const Plugins = lazy(() => import('../PluginsAndWebscripts/Plugins/Plugins'))
const Tasks = lazy(() => import('../Tasks/TasksListRoutes'))
const Subflows = lazy(() => import('../Subflows/Details/Subflows'))
const Webscripts = lazy(
  () => import('../PluginsAndWebscripts/Webscripts/Webscripts'),
)
const Templates = lazy(() => import('../Templates/Templates'))
const Logs = lazy(() => import('../Logs/LogsRoutes'))

// Explore does not work with lazy due to the fact that lazy does not support SSR
// Workaround for the moment - to check newer versions (no plans from React in this moment)
const Explore = loadable(() => import('../Explore/Explore'))
const EditorWrapper = lazy(() => import('../Tasks/Editor/EditorWrapper'))
const Storage = lazy(() => import('../Storage/StorageRouter'))
const DataQueries = lazy(() => import('../DataQueries/DataQueriesList'))
const Byoml = lazy(() => import('../Byoml/ByomlRouter'))
const Applications = lazy(() => import('../Applications/Applications'))
const Nlp = lazy(() => import('../Nlp'))
const Simulator = lazy(() => import('../Simulator/SimulatorLanding'))

const LayoutContainer = styled('div')`
  display: flex;
  min-height: 100vh;
`

const ContentContainer = styled('div')`
  flex: 1;
  max-height: 100vh;
  flex-direction: column;
  overflow-y: auto;
  display: flex;
  background: ${colors.withWeight(concrete, 100)};
`

const SidebarContainer = styled('div')`
  height: 100vh;
  position: relative;

  top: 0;
  left: 0;
  bottom: 0;

  background: ${colors.withWeight(concrete, 100)};
  border-right: solid 1px ${concrete};
`

const dragHandler = e => e.preventDefault()

const Dashboard = () => {
  const [hasError, setHasError] = useState(false)

  useConnectivity()
  const isInitial = useRef(true)
  const whitelabeling = useFlag(['whitelabeling'], true)

  const router = useMemo(() => {
    if (isInitial.current === true) {
      isInitial.current = false
      return createBrowserRouter([{ path: '*', element: <Root /> }])
    }
  }, [isInitial])

  const Content = () => {
    const { isAuthenticated } = useLogin()
    return <>{isAuthenticated && <Outlet />}</>
  }
  const Auth = () => {
    const location = useLocation()

    return (
      <ErrorBoundary
        FallbackComponent={Error}
        onReset={() => setHasError(false)}
        onError={err => Sentry.captureException(err)}
        resetKeys={[hasError, location.pathname]}
      >
        <UseQueryString.Provider>
          <LoginProvider>
            <LicenseContainer.Provider>
              <Branding.Provider>
                <SimulatorContainer.Provider>
                  <BrandingHead />
                  <RunningSimulators />
                  <SidebarContainer2.Provider>
                    <SidebarContainer>
                      <Sidebar />
                    </SidebarContainer>
                    <ContentContainer>
                      <Topbar />
                      <Content />
                    </ContentContainer>
                  </SidebarContainer2.Provider>
                </SimulatorContainer.Provider>
              </Branding.Provider>
            </LicenseContainer.Provider>
          </LoginProvider>
        </UseQueryString.Provider>
      </ErrorBoundary>
    )
  }

  function Root() {
    return (
      <Routes>
        <Route element={<Auth />}>
          <Route
            index
            element={
              <Suspense>
                <LandingPage />
              </Suspense>
            }
          />
          <Route
            path="integrations/*"
            element={
              <PaddingComponent>
                <Integrations />
              </PaddingComponent>
            }
          />

          <Route
            path="alarms/*"
            element={
              <Flag
                keyPath={['alarms']}
                defaultValue={false}
                render={() => (
                  <PaddingComponent>
                    <Alarms />
                  </PaddingComponent>
                )}
                fallback={() => <Navigate to="/" />}
              />
            }
          />

          <Route
            path="storage/*"
            element={
              <Flag
                keyPath="storage"
                defaultValue={false}
                render={() => (
                  <Suspense>
                    <Storage />
                  </Suspense>
                )}
                fallback={() => <Navigate to="/" />}
              />
            }
          />
          <Route
            path="explore"
            element={
              <Flag
                keyPath={['explore']}
                defaultValue={false}
                render={() => (
                  <Suspense>
                    <Explorer />
                  </Suspense>
                )}
                fallback={() => <Navigate to="/" />}
              />
            }
          />
          <Route
            path="resources/*"
            element={
              <PaddingComponent>
                <Resources type={EType.resource} />
              </PaddingComponent>
            }
          />
          <Route
            path="resourcetypes/*"
            element={
              <PaddingComponent>
                <Resources type={EType.resourceType} />
              </PaddingComponent>
            }
          />

          <Route
            path="resourceconstraints/*"
            element={
              <PaddingComponent>
                <ResourceConstraints />
              </PaddingComponent>
            }
          />
          <Route
            path="analytics/queries/*"
            element={
              <Flag
                keyPath="queries"
                defaultValue={false}
                render={() => (
                  <PaddingComponent>
                    <DataQueries />
                  </PaddingComponent>
                )}
                fallback={() => <Navigate to="/" />}
              />
            }
          />
          <Route
            path="analytics/byoml/*"
            element={
              <Flag
                keyPath="byoml"
                defaultValue={false}
                render={() => (
                  <PaddingComponent>
                    <Byoml />
                  </PaddingComponent>
                )}
                fallback={() => <Navigate to="/" />}
              />
            }
          />
          <Route
            path="plugins/*"
            element={
              <RuntimeContainer.Provider>
                <ModalProvider>
                  <Suspense>
                    <Plugins />
                  </Suspense>
                </ModalProvider>
              </RuntimeContainer.Provider>
            }
          />

          <Route
            path="/designer"
            element={
              <Suspense>
                <PlugsContainer.Provider>
                  <TemplateContainer.Provider>
                    <EditorContainer.Provider>
                      <EditorWrapper />
                    </EditorContainer.Provider>
                  </TemplateContainer.Provider>
                </PlugsContainer.Provider>
              </Suspense>
            }
          />

          <Route
            path="subflows/*"
            element={
              <PaddingComponent>
                <Subflows />
              </PaddingComponent>
            }
          />
          <Route
            path="tasks/*"
            element={
              <PaddingComponent>
                <Tasks />
              </PaddingComponent>
            }
          />
          <Route
            path="logs/*"
            element={
              <PaddingComponent style={{ display: 'flex' }}>
                <Logs />
              </PaddingComponent>
            }
          />
          <Route
            path="simulator"
            element={
              <PaddingComponent>
                <Simulator />
              </PaddingComponent>
            }
          />
          <Route
            path="applications"
            element={
              <Flag
                keyPath="applications"
                render={() => (
                  <PaddingComponent>
                    <Applications />
                  </PaddingComponent>
                )}
                defaultValue={false}
                fallback={() => <Navigate to="/" />}
              />
            }
          />

          <Route
            path="webscripts/*"
            element={
              <Flag
                keyPath="webscripts"
                defaultValue={false}
                render={() => (
                  <Suspense>
                    <Webscripts />
                  </Suspense>
                )}
                fallback={() => <Navigate to="/" />}
              />
            }
          />
          <Route
            path="nlp/rulebase/*"
            element={
              <Flag
                keyPath="nlp"
                defaultValue={false}
                render={() => (
                  <PaddingComponent>
                    <Nlp />
                  </PaddingComponent>
                )}
                fallback={() => <Navigate to="/" />}
              />
            }
          />

          <Route
            path="storage/*"
            element={
              <Flag
                keyPath="storage"
                defaultValue={false}
                render={() => (
                  <PaddingComponent>
                    <Storage />
                  </PaddingComponent>
                )}
                fallback={() => <Navigate to="/" />}
              />
            }
          />
          <Route
            path="administration/settings/*"
            element={
              <PaddingComponent>
                <Settings />
              </PaddingComponent>
            }
          />
          <Route
            path="templates/*"
            element={
              <Suspense>
                <PlugsContainer.Provider>
                  <Templates />
                </PlugsContainer.Provider>
              </Suspense>
            }
          />

          {whitelabeling ? (
            <Route path="*" element={<Navigate to="alarms" replace />} />
          ) : (
            <Route path="*" element={<Navigate to="/" replace />} />
          )}
        </Route>
      </Routes>
    )
  }
  return (
    <LayoutContainer
      onDrop={dragHandler}
      onDragEnter={dragHandler}
      onDragOver={dragHandler}
      onDragLeave={dragHandler}
    >
      <RouterProvider router={router} />
    </LayoutContainer>
  )
}

const Explorer = () => (
  <ChartContainer.Provider>
    <ExplorerContainer.Provider>
      <ExploreTopBar />
      <Explore />
    </ExplorerContainer.Provider>
  </ChartContainer.Provider>
)

export default Dashboard
