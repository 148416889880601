import React, { useCallback, useState } from 'react'
import { css } from '@emotion/core'
import { Icon, Label } from '@waylay/react-components'
import { get } from 'lodash-es'
import { InputRow, SmallErrorMessage } from './Configuration.styles'
import { HStack } from '~/components/Common/Stack'
import { testDeclarativelyBound } from '~/lib/util'
import {
  AdditionalPropertyFormatType,
  PropertyFormatType,
  PropertyType,
} from '~/lib/types'
import { IPropertyInput } from '../../types'
import EditorFieldContainer from '../PropertyFields/EditorFieldContainer'
import PropertyInput from '../PropertyInput'

interface IDeclarativeBindingButton {
  isActive: boolean
  onClick: () => void
  testId?: string
}

const DeclarativeBindingButton = ({
  isActive,
  onClick,
  testId = '',
}: IDeclarativeBindingButton) => {
  return (
    <Label
      style={{ cursor: 'pointer' }}
      color={isActive && 'blue'}
      size="tiny"
      onClick={onClick}
      data-testid={`declarative-binding-${testId}`}
    >
      {isActive ? (
        <span>
          <Icon name="link_off" /> Unbind
        </span>
      ) : (
        <span>
          <Icon name="link" /> Bind
        </span>
      )}
    </Label>
  )
}

interface IVariableInput {
  errors?: Object
  isSpecialField?: boolean
  showLabel?: boolean
  setRevalidateDescriptor?: (value: boolean) => void
}

const VariableInput = ({
  label,
  property,
  value,
  errors,
  setFieldValue,
  setDescriptorName,
  setShouldShowDescriptor,
  testId = '',
  isError = false,
  showLabel = true,
  setRevalidateDescriptor,
}: IPropertyInput & IVariableInput) => {
  const [isDeclarativelyBound, setIsDeclarativelyBound] = useState(() => {
    return testDeclarativelyBound(value, property)
  })

  const type = get(property, 'format.type', property.type) as
    | PropertyType
    | PropertyFormatType
    | AdditionalPropertyFormatType

  const hasBindingButton = type !== PropertyFormatType.Code

  const onClickDeclarativeBindingButton = useCallback(() => {
    // make sure bindings are deleted when input type is a number (otherwise you could insert strings in numberfields)
    if (
      type !== PropertyType.String &&
      type !== PropertyType.Object &&
      type !== PropertyType.Array
    ) {
      setFieldValue(property.name, undefined)
    }
    if (typeof setRevalidateDescriptor === 'function') {
      setRevalidateDescriptor(true)
    }
    setIsDeclarativelyBound(isBound => !isBound)
  }, [setFieldValue, setIsDeclarativelyBound, property])

  return (
    <div
      style={{ padding: '0.3rem 0' }}
      data-testid={`variable-input-${testId}`}
    >
      {type !== PropertyFormatType.AiPluginDescriptor &&
        type !== PropertyFormatType.AiTemplateDescriptor && (
          <HStack
            style={{
              alignItems: 'center',
              justifyContent: showLabel ? 'space-between' : 'flex-end',
              padding: '0.2rem 0',
            }}
          >
            {showLabel && (
              <label
                css={css`
                  font-size: 0.9em;
                  ${isDeclarativelyBound && 'color: #1E90FF !important;'}
                `}
                htmlFor={property.name}
                data-testid={`field-label-${property.name}`}
              >
                {label || property.name}
              </label>
            )}
            {property.mandatory && (
              <label
                css={{
                  marginRight: 'auto',
                  color: '#B00020 !important',
                  fontSize: '0.9em',
                }}
              >
                *
              </label>
            )}
            {hasBindingButton && (
              <DeclarativeBindingButton
                testId={testId}
                isActive={isDeclarativelyBound}
                onClick={onClickDeclarativeBindingButton}
              />
            )}
          </HStack>
        )}
      {isDeclarativelyBound ? (
        <EditorFieldContainer
          property={property}
          value={value}
          setFieldValue={setFieldValue}
          isDeclarativelyBound={isDeclarativelyBound}
        />
      ) : (
        <InputRow>
          <div style={{ flex: '1' }}>
            <PropertyInput
              property={property}
              value={value}
              isError={isError}
              setFieldValue={setFieldValue}
              setDescriptorName={setDescriptorName}
              setShouldShowDescriptor={setShouldShowDescriptor}
            />
          </div>
        </InputRow>
      )}

      {errors?.[property.name] && (
        <SmallErrorMessage>{errors[property.name]}</SmallErrorMessage>
      )}
    </div>
  )
}
export default VariableInput
